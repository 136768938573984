import { LIST_SORACOM_SUBSCRIBERS, CLEAR_SORACOM_SUBSCRIBERS } from '../actionTypes/soracomSubscribers'

export default (state = [[], 0], action) => {
    switch (action.type) {
        case LIST_SORACOM_SUBSCRIBERS:
            return action.payload
        case CLEAR_SORACOM_SUBSCRIBERS:
            return [[], 0];
        default:
            return state

    }
}
