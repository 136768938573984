import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles'


import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import SearchTable from '../../components/Table/Table';

import * as actions from '../../store/actions/sorcomSubscribers';
import { Refresh } from '@material-ui/icons';
import { Hidden } from '@material-ui/core';

const SoracomUserDetails = props => {
    const classes = styles();

    const [soracomUser, setSoracomUser] = useState(null);
    const [soracomSubscriber, setSoracomSubscriber] = useState(null);
    const [soracomSubscriberLive, setSoracomSubscriberLive] = useState(null);

    const [isProcessing, setProcessing] = useState(false);

    const handleDelete = () => {
        props.deleteSoracomUser(props.selectedUser[1], (err, response) => {
            if (err) return props.showNotification({
                color: 'warning',
                message: err.response.data.message,
                time: 10000
            });

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });

            props.getSoracomUsers();
            props.setModalOpen(false);
        });
    }

    const refreshLiveData = () => {
        setSoracomSubscriberLive(null);
        props.getSoracomSubscriberDetailsLive(props.selectedSubscriber[0], (err, response) => {
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 10000
                });
            setSoracomSubscriberLive(response.data);
        });
    }

    useEffect(() => {
        //retrieve the details
        props.getSoracomSubscriberDetails(props.selectedSubscriber[0], (err, response) => {
            if (err) {
                props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 10000
                });
                props.setModalOpen(false);
            } else {
                setSoracomUser(response.data.soracomUser);
                setSoracomSubscriber(response.data.soracomSubscriber);
                refreshLiveData();
            }
        });

        return () => {
            setSoracomUser(null);
            setSoracomSubscriber(null);
            setSoracomSubscriberLive(null);
        }
    }, []) //only when the component is mounted


    const soracomUserDetails = soracomUser ? (<TableContainer component={Paper}>
        <Table aria-label="Soracom User Details">
            <TableBody>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{soracomUser.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Operator Id</TableCell>
                    <TableCell>{soracomUser.operatorId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>{soracomUser.userName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>{new Date(soracomUser.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>) : (<CircularProgress />);

    const subscriberDetails = soracomSubscriber ? (<TableContainer component={Paper}>
        <Table aria-label="Soracom User Details">
            <TableBody>
                <TableRow>
                    <TableCell>IMSI</TableCell>
                    <TableCell>{soracomSubscriber.imsi}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>MSISDN</TableCell>
                    <TableCell>{soracomSubscriber.msisdn}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ICCID</TableCell>
                    <TableCell>{soracomSubscriber.iccid}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>{soracomSubscriber.serialNumber}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>SimId</TableCell>
                    <TableCell>{soracomSubscriber.simId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Module Type</TableCell>
                    <TableCell>{soracomSubscriber.moduleType}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>{soracomSubscriberLive ? soracomSubscriberLive.status : (<CircularProgress />)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Is Live</TableCell>
                    <TableCell>{soracomSubscriberLive ? soracomSubscriberLive.online ? "Yes" : "No" : (<CircularProgress />)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>{new Date(soracomSubscriber.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>) : (<CircularProgress />);

    return (
        <GridItem xs={12}>
            <Card >
                <CardBody>
                    <GridItem xs={12} md={8} lg={6} className={classes.buttonHolder}>
                        <div className={classes.buttonHolder}>
                            <Button variant="contained" color="primary" startIcon={<Refresh />} onClick={refreshLiveData} >Refresh</Button>
                            <Button variant="contained" color="primary" startIcon={<CloseIcon />} onClick={() => props.setModalOpen(false)} >Close</Button>
                        </div>
                    </GridItem>
                    <div style={{ display: "flex", flexDirection: 'row' }}>
                        <GridItem xs={12} sm={12} md={8} lg={7} className={classes.buttonHolder}>
                            <Typography variant='h5'>Subscriber</Typography>
                            {subscriberDetails}
                        </GridItem>
                        <Hidden smDown>
                            <GridItem md={4} lg={5} className={classes.buttonHolder}>
                                <Typography variant='h5'>Raw Data from soracom</Typography>
                                <pre>
                                    {soracomSubscriberLive ? JSON.stringify(soracomSubscriberLive, null, 2) : (<CircularProgress />)}
                                </pre>
                            </GridItem>
                        </Hidden>
                    </div>
                    <Typography variant='h5'>Related User</Typography>
                    {soracomUserDetails}
                </CardBody>
            </Card>
        </GridItem>
    )
}

const styles = makeStyles({
    buttonHolder: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export default connect(null, actions)(SoracomUserDetails);
