import { LIST_SORACOM_USERS, CLEAR_SORACOM_USERS } from '../actionTypes/soracomUsers';
import { BACKEND } from '../../constants'
import axios from '../../axios';

export const getSoracomUsers = () => async (dispatch, getState) => {
    let response
    try {

        response = await axios.get(BACKEND.LIST_SORACOM_USER.uri);

        dispatch({
            type: LIST_SORACOM_USERS,
            payload: response.data
        })
    } catch (e) {

    }
}

export const getSoracomUserDetails = (operatorId, callback) => async (dispatch) => {
    let response
    try {

        response = await axios.get(BACKEND.SORACOM_USER_DETAILS.uri.replace(':?1', operatorId));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const getSoracomUserSubscribers = (operatorId, page, rowsPerPage, searchOn, search, callback) => async (dispatch) => {
    let response
    try {

        response = await axios.get(BACKEND.SORACOM_USER_SUBSCRIBERS.uri.replace(':?1', operatorId), { params: { page, rowsPerPage, searchOn, search } });
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const deleteSoracomUser = (operatorId, callback) => async (dispatch) => {
    let response
    try {

        response = await axios.delete(BACKEND.SORACOM_USER_DETAILS.uri.replace(':?1', operatorId));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const clearSoracomUser = () => {
    return {
        type: CLEAR_SORACOM_USERS,
        payload: null
    }
}
