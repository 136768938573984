import React, { useState, useEffect } from 'react'
import SearchTable from '../../components/Table/Table';
import withBase from '../../hoc/withBase';

import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

import { connect } from 'react-redux';

import * as actions from '../../store/actions/invoiceDownloads'
import { findUnits } from '../../helpers/helper';


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px'
    },
}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Soracom user details"
            aria-describedby="Soracom user details">
            <Fade in={props.modalOpen}>
                <div className={classes.paper} style={{
                    width: '90vw',
                    minHeight: '50vh',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
}

const SoracomInvoicesDetails = (props) => {
    const [selectedInvoiceProcess, setSelectedInvoicesProcess] = useState(null);
    const [downloadedInvoices, setDownloadedInvoices] = useState([]);
    const [selectedDownloadedInvoices, setSelectedDownloadedInvoices] = useState(null);
    const [soracomInvoicesDetailsModelOpen, setSoracomInvoicesModelOpen] = useState(false);

    useEffect(() => {
        props.getInvoiceDownloadProcessDetails(props.match.params.processId, (err, data) => {
            if (!err) {
                setSelectedInvoicesProcess(data.data.invoiceDownloadProgress);
                setDownloadedInvoices(data.data.allInvoices);
                return;
            }
            //TODO Show error loading data message
        });

        return () => {
            setSelectedInvoicesProcess(null);
            setDownloadedInvoices([]);
        }
    }, [])

    const headCells = [
        { id: 'name', label: 'Name', isSearchable: true },
        { id: 'status', label: 'Process Status', isSearchable: true },
        { id: 'operatorId', label: 'Operator Id', isSearchable: true },
        { id: 'totalAmount', label: 'Total Amount', isSearchable: true, type: 'Float' },
        { id: 'createdAt', label: 'Created', isSearchable: false, type: 'Date' }
    ];

    const headCellsInvoiceDetails = [
        { id: 'itemName', label: 'Item Name', isSearchable: true },
        { id: 'unitPrice', label: 'Unit Price (USD)', isSearchable: true },
        { id: 'quantity', label: 'Quantity', isSearchable: true },
        { id: 'amount', label: 'Amount (USD)', isSearchable: true },
    ]

    const handleClick = (event, row) => {
        const selectedInvoice = downloadedInvoices.find(invoice => invoice.operatorId === row[2]);
        setSelectedDownloadedInvoices(selectedInvoice)
        setSoracomInvoicesModelOpen(true);
    }

    const invoiceDownloadProgressDetails = selectedInvoiceProcess ? (<TableContainer component={Paper}>
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>{selectedInvoiceProcess.invoiceDate}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Triggered By</TableCell>
                    <TableCell>{selectedInvoiceProcess.triggeredBy}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>{selectedInvoiceProcess.downloadStatus}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Total Amount (USD)</TableCell>
                    <TableCell>$ {selectedInvoiceProcess.totalAmount.toFixed(4)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>{new Date(selectedInvoiceProcess.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>) : (<h2>No data to display</h2>);

    const invoiceDetails = selectedDownloadedInvoices ? (<TableContainer component={Paper}>
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{selectedDownloadedInvoices.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Operator Id</TableCell>
                    <TableCell>{selectedDownloadedInvoices.operatorId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Registered Subscribers</TableCell>
                    <TableCell>{selectedDownloadedInvoices.registered}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Activated Subscribers</TableCell>
                    <TableCell>{selectedDownloadedInvoices.activated}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>{selectedDownloadedInvoices.invoiceDate}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Total Amount (USD)</TableCell>
                    <TableCell>$ {selectedDownloadedInvoices.total.toFixed(4)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>{new Date(selectedDownloadedInvoices.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>) : (<h2>No data to display</h2>);

    const invoiceSummary = selectedDownloadedInvoices && selectedDownloadedInvoices.summary && selectedDownloadedInvoices.summary.length ?
        (
            <SearchTable rows={selectedDownloadedInvoices.summary.map(summary => [
                summary.billItemName,
                !summary.unitPrice ? "N/A" : `$ ${summary.unitPrice.toFixed(4)}`,
                !summary.quantity || isNaN(summary.quantity) ? "N/A" : `${summary.quantity.toFixed(4)} ${findUnits(summary.billItemName)}`,
                !summary.amount ? "N/A" : `$ ${summary.amount.toFixed(4)}`
            ])} headCells={headCellsInvoiceDetails} name="Invoice Details" />
        ) : (<h2>No Invoice Details</h2>)

    return (
        <>
            <SimpleModal modalOpen={soracomInvoicesDetailsModelOpen} setModalOpen={setSoracomInvoicesModelOpen}>
                {invoiceDetails}
                <br />
                {invoiceSummary}
            </SimpleModal>
            {invoiceDownloadProgressDetails}
            <SearchTable rows={downloadedInvoices.map(process => [process.name, process.status, process.operatorId, process.total.toFixed(4), new Date(process.createdAt)])} headCells={headCells} onClick={handleClick} name="Invoices List" />
        </>
    )
}
export default withBase(connect(null, actions)(SoracomInvoicesDetails));
