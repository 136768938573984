import { combineReducers } from 'redux';

import Auth from './auth';
import SoracomUsers from './soracomUsers';
import SoracomSubscribers from './soracomSubscribers';
import LocalUsers from './localusers';
import ImportExport from './importExport';
import Keepass from './keepass';

export default combineReducers({
    auth: Auth,
    soracomUsers: SoracomUsers,
    soracomSubscribers: SoracomSubscribers,
    users: LocalUsers,
    importExport: ImportExport,
    keepass: Keepass
});
