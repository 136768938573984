import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles'

//import the required componenents
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import logo from '../../assets/img/Logo-new-2021.svg'
import GoogleLogin from '../../assets/img/btn_google_signin_dark_normal_web@2x.png'

import * as actions from '../../store/actions/auth';

import { connect } from 'react-redux'

import Snackbar from "../../components/Snackbar/Snackbar"

import { BACKEND } from "../../constants"

const LoginSignup = (props) => {
    const classes = styles();

    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const [inputEmailError, setInputEmailError] = useState(false);
    const [inputPasswordError, setInputPasswordError] = useState(false);

    const [isProcessing, setProcessing] = useState(false);

    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationColor, setNotificationColor] = useState('success');
    const [notificatonMesssage, setNotificatonMesssage] = useState('');


    useEffect(() => {
        props.refreshAuth();
        //check if i have status
        const queryString = props.location.search;
        if (queryString.length) {
            const parsedQuery = parseQuery(queryString);
            if (parsedQuery.status == 'error') {
                setNotificationColor('danger');
                setNotificatonMesssage(parsedQuery.message)
                setNotificationOpen(true);
                setTimeout(function () {
                    setNotificationOpen(false);
                }, 3000);
            }
            if (parsedQuery.status === 'success') {
                //get the cookie
                const cookies = document.cookie.split('; ').reduce((prev, current) => {
                    const [name, value] = current.split('=');
                    prev[name] = decodeURIComponent(value);
                    return prev
                }, {});
                if (cookies.token && cookies.token.length) {
                    var d = new Date();
                    d.setTime(d.getTime() - (1 * 24 * 60 * 60 * 1000));
                    document.cookie = `token=; expires=${d.toUTCString()}; path=/`;

                    props.refreshToken(cookies.token);
                }
            }
        }
        //refresh the auth
        if (props.auth.authenticated) {
            if (props.auth.token) {
                props.history.push('/');
            }
        }
    }, [props.auth]);

    function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    const handleEmailEvent = (event) => {
        setUserEmail(event.target.value);
        setInputEmailError(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(event.target.value))
    }

    const handlePasswordEvent = (event) => {
        setUserPassword(event.target.value)
        setInputPasswordError(event.target.value.length === 0)
    }

    const attemptSignin = () => {
        setProcessing(true);
        props.signIn({ userEmail, userPassword }, (error, user) => {
            setProcessing(false);

            if (error) {
                setNotificationColor('danger');
                setNotificatonMesssage('Login error. Please check the credentials.')
                setNotificationOpen(true);
                setTimeout(function () {
                    setNotificationOpen(false);
                }, 3000);

                return;
            }
            props.history.push('/dashboard');

        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!inputEmailError)
            setInputEmailError(userEmail.length === 0)

        if (!inputPasswordError)
            setInputPasswordError(userPassword.length === 0)

        //if everything is alright then dispatch the request
        if (!inputEmailError && !inputPasswordError && userEmail.length !== 0 && userPassword.length !== 0) {
            attemptSignin();
        }
    }

    return (
        <div className={classes.center}>
            <Snackbar place='tr' color={notificationColor} message={notificatonMesssage} open={notificationOpen} />
            <GridItem xs={12} sm={8} md={6} lg={3}>
                <Card >
                    <GridItem xs={12} >
                        <div className={classes.logoHolder}>
                            <img src={logo} />
                        </div>
                    </GridItem>
                    <CardBody>
                        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Email"
                                    id="userEmail"
                                    inputProps={{
                                        value: userEmail,
                                        onChange: handleEmailEvent,
                                        error: inputEmailError
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root
                                    }}

                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root,
                                    }}
                                    inputProps={{
                                        type: "password",
                                        value: userPassword,
                                        onChange: handlePasswordEvent,
                                        error: inputPasswordError
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} className={classes.logoHolder}>
                                <div className={classes.buttonHolder}>
                                    <Button type="submit" color="primary"
                                        disabled={isProcessing}
                                    >Login</Button>
                                </div>
                            </GridItem>
                        </form>

                        <hr />
                        <GridItem xs={12} >
                            <div className={classes.logoHolder}>
                                <a href={`${BACKEND.BASE}/auth/google`}><img src={GoogleLogin} /></a>
                            </div>
                        </GridItem>
                    </CardBody>
                </Card>
            </GridItem>
        </div>
    )

}

const styles = makeStyles({
    logoHolder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: '2em'
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        width: '100vw',
        backgroundColor: '#0F1837',
        backgroundImage: 'url(https://thinxtra.com/wp-content/uploads/Helix_0.svg)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    buttonHolder: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    root: {
        marginTop: '8px'
    }
});

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, actions)(LoginSignup);
