import { LIST_LOCAL_USERS } from '../actionTypes/localUsers';
import { BACKEND } from '../../constants';
import axios from '../../axios';

export const getLocalUsers = () => async (dispatch, getState) => {
    let response
    try {

        response = await axios.get(BACKEND.LIST_LOCAL_USERS.uri);

        dispatch({
            type: LIST_LOCAL_USERS,
            payload: response.data
        })
    } catch (e) {

    }
}

export const addLocalUser = (firstName, lastName, email, password, userLevel, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.LIST_LOCAL_USERS.uri, {
            firstName, lastName, email, password, userLevel,
        });
        callback(null, response);
    } catch (e) {
        callback(e);
    }
}


export const updateLocalUser = (firstName, lastName, email, userLevel, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.LOCAL_USER_DETAILS.uri.replace(':?1', decodeURIComponent(email)), {
            firstName, lastName, userLevel
        });
        callback(null, response);
    } catch (e) {
        callback(e);
    }
}

export const changePassword = (email, newPassword, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.patch(BACKEND.LOCAL_USER_DETAILS.uri.replace(':?1', decodeURIComponent(email)), {
            newPassword
        });
        callback(null, response);
    } catch (e) {
        callback(e);
    }
}

export const deleteteLocalUser = (email, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.delete(BACKEND.LOCAL_USER_DETAILS.uri.replace(':?1', decodeURIComponent(email)));
        callback(null, response);
    } catch (e) {
        callback(e);
    }
}
