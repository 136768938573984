import axios from 'axios'
import { AUTH_USER, AUTH_ERROR, SIGN_OUT, REFRESH_AUTH } from '../actionTypes/auth';
import { BACKEND } from '../../constants';
import localStorageService from '../../service/localStorageService'

export const signIn = ({ userEmail, userPassword }, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(`${BACKEND.BASE}${BACKEND.LOGIN.uri}`, { userEmail, password: userPassword }, {
            headers: { 'content-type': 'application/json' },
        });
        dispatch({
            type: AUTH_USER,
            payload: {
                authenticated: true,
                token: response.data.token,
                user: response.data.user,
                expiresOn: response.data.expiresOn
            }
        })
        localStorageService.setAuthenticated(true);
        localStorageService.setToken(response.data.token);
        localStorageService.setExpiry(response.data.expiresOn);
        callback(null, response.data)
    } catch (e) {
        dispatch({
            type: AUTH_ERROR,
            payload: e.message
        });
        callback(e, null)
    }
}

export const refreshToken = (token) => async (dispatch) => {
    let response
    try {
        response = await axios.post(`${BACKEND.BASE}${BACKEND.REFRESH_TOKEN.uri}`, {}, {
            headers: { 'content-type': 'application/json', 'Authorization': token },
        });
        dispatch({
            type: AUTH_USER,
            payload: {
                authenticated: true,
                token: response.data.token,
                user: response.data.user,
                expiresOn: response.data.expiresOn
            }
        })
        localStorageService.setAuthenticated(true);
        localStorageService.setToken(response.data.token);
        localStorageService.setExpiry(response.data.expiresOn);
    } catch (e) {
        dispatch({
            type: AUTH_ERROR,
            payload: e.message
        });
    }
}

export const signOut = () => (dispatch) => {
    //remove from the storage
    localStorageService.setAuthenticated(false);
    localStorageService.setToken(null);
    localStorageService.setExpiry(null);

    dispatch({
        type: SIGN_OUT
    });
}

export const userDetails = (callback) => async (dispatch, getState) => {
    //get the user details from the server
    const currentAuthState = getState().auth;
    if (currentAuthState.token && ((new Date(currentAuthState.expiresOn).getTime()) > (new Date().getTime()))) {
        let response
        try {
            response = await axios.get(`${BACKEND.BASE}/auth/user`, {
                headers: { 'content-type': 'application/json', 'Authorization': currentAuthState.token },
            });
            dispatch({
                type: AUTH_USER,
                payload: {
                    authenticated: true,
                    token: response.data.token,
                    user: response.data.user,
                    expiresOn: response.data.expiresOn
                }
            })
            localStorage.setItem('authenticated', true)
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('expiresOn', response.data.expiresOn)
            callback(null, response.data)
        } catch (e) {
            dispatch({
                type: AUTH_ERROR,
                payload: e.message
            });
            callback(e, null)
        }
    }

}

export const refreshAuth = () => {
    //get all the data and send it to the 
    return {
        type: REFRESH_AUTH,
        payload: {
            authenticated: localStorageService.getAuthenticated(),
            token: localStorageService.getToken(),
            expiresOn: localStorageService.getExpiry()
        }

    }
}
