import { LIST_SORACOM_USERS, CLEAR_SORACOM_USERS } from '../actionTypes/soracomUsers'

export default (state = [], action) => {
    switch (action.type) {
        case LIST_SORACOM_USERS:
            return action.payload
        case CLEAR_SORACOM_USERS:
            return []
        default:
            return state

    }
}
