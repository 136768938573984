import React from 'react';
import {
  Chart as ChartJS, CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Typography } from '@material-ui/core';

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

const ActiveRegisteredBar = props => {


  //We get two things from the props daily billings and legend

  const { registered, activated } = props;
  const data = {
    labels: ['Registered', 'Active'],
    datasets: [
      {
        label: 'Registered',
        backgroundColor: ['rgb(53, 162, 235)', 'rgb(75, 192, 192)'],
        data: [registered, activated]
      }
    ]
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      datalabels: {
        display: true,
        color: "black",
        formatter: (value) => `${value} sim subscribers`,
        anchor: "end",
        align: "start",
        offset: -20,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  const chartToDisplay = (<Bar options={options} data={data} plugins={[ChartDataLabels]} />)

  return (
    <>
      <Typography variant='h5'>Subscriber Status</Typography>
      {chartToDisplay}
    </>
  )
}

export default ActiveRegisteredBar;