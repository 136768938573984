import React, { useState, useEffect } from 'react'
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux';

import GridItem from "../../components/Grid/GridItem";

import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import Step5 from './steps/Step5'

import * as actions from '../../store/actions/registration';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width: '100%',
        height: '100%'
    },
    noPadding: {
        padding: '0 !important',
        maxHeight: '95vh',
        maxWidth: '95vw',
        overflow: 'scroll !important',
    }

}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Add new customer model"
            aria-describedby="Add new customer">
            <Fade in={props.modalOpen}>
                <GridItem container spacing={0} xs={10} sm={8} md={6} lg={4} classes={{ root: classes.noPadding }}>
                    <div className={classes.paper}>
                        {props.children}
                    </div>
                </GridItem>
            </Fade>
        </Modal>
    )
}


const OngoingRegistration = (props) => {
    const classes = useStyles();

    const [addUserModalOpen, setAddUserModalOpen] = useState(false);

    const [step, setStep] = useState(1);

    const [ongoingCustomers, setOngoingCustomers] = useState([]);

    const [workingCustomer, setWorkingCustomer] = useState(null);

    const [credentials, setCredentials] = useState([]);

    const getOngoingCustomers = (page = 1) => {
        props.getOnRegistrationProcess(page, (err, response) => {
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 5000
                });
            setOngoingCustomers(response.data);
        })
    }

    const getWorkingCustomerDetails = id => {
        props.getRegistrationDetails(id, (err, response) => {
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 5000
                });

            setWorkingCustomer(response.data);
        })
    }

    const handleCustomerDetailUpdate = (err, response, step) => {
        if (err)
            return props.showNotification({
                color: 'warning',
                message: err.response.data.message,
                time: 5000
            });

        props.showNotification({
            color: 'success',
            message: response.data.message,
            time: 5000
        });
        setWorkingCustomer(response.data.registeredCustomer);
        if (step)
            setStep(step);
    }

    const setModalOpen = (newStatus) => {
        if (!newStatus)
            getOngoingCustomers();
        setAddUserModalOpen(newStatus);
    }

    const deleteRegistration = () => {
        if (workingCustomer)
            props.deleteRegistration(workingCustomer._id, (err, response) => {
                if (err)
                    return props.showNotification({
                        color: 'warning',
                        message: err.response.data.message,
                        time: 5000
                    });

                props.showNotification({
                    color: 'success',
                    message: response.data.message,
                    time: 5000
                });
                setWorkingCustomer(null);
                setModalOpen(false);
            });
    }

    const finishStep = (step, dataTopass) => {
        switch (step) {
            case 1:
                if (workingCustomer && workingCustomer._id) {
                    props.finishStep1(workingCustomer._id, ...dataTopass, handleCustomerDetailUpdate);
                    break;
                }
                props.startRegistration(...dataTopass, handleCustomerDetailUpdate);
                break;
            case 2:
                props.finishStep2(workingCustomer._id, ...dataTopass, handleCustomerDetailUpdate);
                break;

            case 3:
                setCredentials([...dataTopass]);
                setStep(4);
                break;
            case 4:
                props.finishStep3(workingCustomer._id, ...dataTopass, handleCustomerDetailUpdate);
                setCredentials([])
                break;
        }
    }

    const onTableItemClicked = (event, row) => {
        const clickedUser = ongoingCustomers.find(customer => customer.step1.googleGroup == row[2]);
        setWorkingCustomer(clickedUser);
        setAddUserModalOpen(true);
        setStep(clickedUser.currentPosition + 1);
        getWorkingCustomerDetails(clickedUser._id);
    }

    useEffect(() => {
        getOngoingCustomers();
    }, [])

    const startContinuousUpdate = () => {
        return setInterval(() => {
            getWorkingCustomerDetails(workingCustomer._id)
        }, 1500);
    }

    let displayStep;
    switch (step) {
        case 1:
            displayStep = (<Step1 next={finishStep} workingCustomer={workingCustomer} deleteRegistration={deleteRegistration} />);
            break;
        case 2:
            displayStep = (<Step2 prev={() => setStep(1)} next={finishStep} workingCustomer={workingCustomer} deleteRegistration={deleteRegistration} />);
            break;

        case 3:
            displayStep = (<Step3 prev={() => setStep(2)} next={finishStep} workingCustomer={workingCustomer} deleteRegistration={deleteRegistration} />);
            break;
        case 4:
            displayStep = (<Step4 prev={() => setStep(3)} next={finishStep} workingCustomer={workingCustomer} credentials={credentials} deleteRegistration={deleteRegistration} />);
            break;
        case 5:
            displayStep = (<Step5 prev={() => setStep(4)} next={() => setAddUserModalOpen(false)} workingCustomer={workingCustomer} startContinuousUpdate={startContinuousUpdate} />);
            break;
    }

    const headCells = [
        { id: 'customerName', label: 'Customer Name', isSearchable: true },
        { id: 'customerEmail', label: 'Customer Email', isSearchable: true },
        { id: 'customerGroup', label: 'Customer Group', isSearchable: true },
        { id: 'completedStep', label: 'Completed Step', isSearchable: false },
        { id: 'createdAt', label: 'Created At', isSearchable: false, type: 'Date' },
        { id: 'updatedAt', label: 'Updated At', isSearchable: false, type: 'Date' },
    ];

    return (
        <>
            <SimpleModal modalOpen={addUserModalOpen} setModalOpen={setModalOpen}>
                {displayStep}
            </SimpleModal>

            <Table rows={ongoingCustomers.map(customer => [customer.step1.customerName, customer.step1.customerEmail, customer.step1.googleGroup, customer.currentPosition, new Date(customer.createdAt), new Date(customer.updatedAt)])} headCells={headCells} onClick={onTableItemClicked} name="Customer Registration" />
            <Fab size="small" className={classes.fab} color="primary" aria-label="add" onClick={() => {
                setWorkingCustomer(null);
                setCredentials([]);
                setStep(1);
                setAddUserModalOpen(true);
            }}>
                <AddIcon />
            </Fab>
        </>
    )
}

export default withBase(connect(null, actions)(OngoingRegistration));
