import { createMuiTheme } from '@material-ui/core/styles'

//define the theme colors
const COLOR_PRIMARY = "#663694";
const COLOR_PRIMARY_DARK = "#452667";
const COLOR_SECONDARY = "#333366";
const COLOR_ACCENT = "#FFCC05";


const theme = createMuiTheme({
    palette: {
        common: {
            accent: COLOR_ACCENT,
        },
        primary: {
            main: COLOR_PRIMARY,
            dark: COLOR_PRIMARY_DARK
        },
        secondary: {
            main: COLOR_SECONDARY
        }
    },

});

export default theme;