import { KEEPASS_DATA_WITH_USERS, CLEAR_KEEPASS_DATA_WITH_USERS } from '../actionTypes/keepass'

export default (state = { keepass: [], users: [] }, action) => {
    switch (action.type) {
        case KEEPASS_DATA_WITH_USERS:
            return {
                ...state,
                keepass: action.payload.keepassEntries,
                users: action.payload.nonExistingEmails
            }
        case CLEAR_KEEPASS_DATA_WITH_USERS:
            return { keepass: [], users: [] }
        default:
            return state
    }
}
