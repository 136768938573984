import React, { useEffect, useRef, useState } from 'react';
import withBase from '../../hoc/withBase';

import axios from '../../axios';

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from '../../components/Card/CardBody';
import CardFooter from "../../components/Card/CardFooter.js";
import Danger from "../../components/Typography/Danger.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";


import Person from "@material-ui/icons/Person";
import PeopleIcon from '@material-ui/icons/People';
import SimCardIcon from '@material-ui/icons/SimCard';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import { BACKEND } from '../../constants'

import { Link } from 'react-router-dom'

import palette from 'google-palette';
import DailyBillingsChart from './DailyBillingsChart';
import MonthlyBillingsChart from './MonthlyBillingsChart';
import SubscribersLogsChart from './SubscribersLogsChart';
import ActiveRegisteredChart from './ActiveRegisteredChart';
import { Button, Typography } from '@material-ui/core';
import { capitalizeFirstLetter } from '../../helpers/helper';

const useStyles = makeStyles(styles);

function hexToRGBA(hex, opacity) {
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';

}
const createLegend = (allSoracomUsers) => {
    //This function creates the legend for all the soraocom Users that exist
    const colors = palette('tol-rainbow', Object.keys(allSoracomUsers).length);
    return allSoracomUsers.reduce((dict, user, idx) => ({ ...dict, [user.operatorId]: { ...user, color: hexToRGBA(colors[idx]), disabled: false } }), {})
}

const Dashboard = props => {
    const classes = useStyles();
    const [dashboardData, setDashboardData] = useState(null);
    const [allOperators, setAllOperators] = useState([]);
    const [sortedOperators, setSortedOperators] = useState([]);
    const [dailyBillings, setDailyBillings] = useState({});
    const [monthlyBillings, setMonthlyBillings] = useState([]);
    const [subscriberLogs, setSubscribersLogs] = useState([]);
    const [currentSubscriberStatus, setCurrentSubscriberStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [legendSelection, setLegendSelection] = useState(true);

    useEffect(() => {
        axios.get(BACKEND.DASHBOARD.uri)
            .then(response => {
                setDashboardData(response.data);
                setIsLoading(false);
            })
            .catch(err => {

            })
        async function fetchData() {
            const { data } = await axios.get(BACKEND.DASHBOARD_OPERATORS.uri);
            setAllOperators(createLegend(data));
            setSortedOperators(data.map(operator => operator.operatorId));
            console.log(data.map(operator => operator.name))

            const { data: { dailyBillings } } = await axios.get(BACKEND.DASHBOARD_DAILY_BILLINGS.uri);
            setDailyBillings(dailyBillings);

            const { data: { monthlyUniqueBillings } } = await axios.get(BACKEND.DASHBOARD_MONTHLY_BILLINGS.uri);
            setMonthlyBillings(monthlyUniqueBillings);

            const { data: { subscribersLogs } } = await axios.get(BACKEND.DASHBOARD_SUBSCRIBERS_LOGS.uri);
            setSubscribersLogs(subscribersLogs);

            const { data: { subscribersStatus } } = await axios.get(BACKEND.DASHBOARD_CURRENT_SUBSCRIBER_STATUS.uri);
            setCurrentSubscriberStatus(subscribersStatus);
        }
        fetchData();
    }, [])

    return (
        <>
            <GridContainer>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <PeopleIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Soracom Users</p>
                            <h3 className={classes.cardTitle}>
                                {isLoading ? 'Loading...' : dashboardData.basicStats.soracomUserssCount}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Link to='/soracom-users' style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                    <PeopleIcon />
                                    Show Soracom Users
                                </Link>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <SimCardIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Subscribers(Active/Registered)</p>
                            <h3 className={classes.cardTitle}>
                                {isLoading ? 'Loading...' : dashboardData.basicStats.activeSubscribersCount}/{isLoading ? 'Loading...' : dashboardData.basicStats.subscribersCount}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Link to='/soracom-subscribers' style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                    <SimCardIcon />
                                    Show Soracom Subscribers
                                </Link>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Person />
                            </CardIcon>
                            <p className={classes.cardCategory}>Local Users</p>
                            <h3 className={classes.cardTitle}>
                                {isLoading ? 'Loading...' : dashboardData.basicStats.usersCount}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Link to='/users' style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                    <Person />
                                    Show Local Users
                                </Link>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <LockOpenIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Keepass Entries</p>
                            <h3 className={classes.cardTitle}>
                                {isLoading ? 'Loading...' : dashboardData.basicStats.keepassesCount}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Link to='/keepass' style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                    <LockOpenIcon />
                                    Show Keepass Entries
                                </Link>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>

                <GridItem xs={12} md={2}>
                    <Card>
                        <CardBody>
                            <Typography variant='h5' classes={{ pt: 2 }}>Legend</Typography>
                            <Button type="submit" color="primary" variant='outlined' onClick={() => {
                                // Update the legend
                                const tmp = { ...allOperators };
                                for (const operatorId of Object.keys(allOperators)) {
                                    tmp[operatorId].disabled = legendSelection;
                                }
                                setAllOperators(tmp);
                                setLegendSelection(() => !legendSelection);
                                //Update the key
                            }}
                            >{legendSelection ? 'Unselect All' : 'Select All'}</Button>
                            <div style={{ maxHeight: '60vh', overflow: 'auto' }}>

                                {Object.keys(allOperators).length ? (<ul style={{ padding: 0 }}>
                                    {sortedOperators.map(operator => (<li style={{ listStyleType: 'none', cursor: 'pointer', fontSize: '1.2em', marginTop: '5px' }} onClick={() => {
                                        const tmp = { ...allOperators };
                                        tmp[operator].disabled = !allOperators[operator].disabled
                                        setAllOperators(tmp);
                                    }}><div style={{ width: 15, height: 10, backgroundColor: allOperators[operator].disabled ? 'transparent' : allOperators[operator].color, display: 'inline-block', border: `2px dotted ${allOperators[operator].color}` }}></div> {capitalizeFirstLetter(allOperators[operator].name.replace('soracom_', '').replace('@thinxtra.com', ''))}</li>))}
                                </ul>) : ""}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} md={10}>
                    <GridItem xs={12}>
                        <ActiveRegisteredChart legend={allOperators} currentSubscriberStatus={currentSubscriberStatus} />
                    </GridItem>
                    <GridItem xs={12}>
                        <Card>
                            <DailyBillingsChart legend={allOperators} dailyBillings={dailyBillings} />
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <Card>
                            <MonthlyBillingsChart legend={allOperators} monthlyBillings={monthlyBillings} />
                        </Card>
                    </GridItem>
                </GridItem>
                <GridItem xs={12}>
                    <SubscribersLogsChart legend={allOperators} subscriberLogs={subscriberLogs} />
                </GridItem>
            </GridContainer >
        </>

    )
}

export default withBase(Dashboard);
