import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles'

import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux'
import * as actions from '../../store/actions/keepass';

const AddKeepassAuto = props => {
    const classes = styles();
    const [isProcessing, setProcessing] = useState(false);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [notes, setNotes] = useState('');

    const [titleError, setTitleError] = useState(false);


    const handleTitle = (event) => {
        setTitleError(!event.target.value.length)
        setTitle(event.target.value)
    }

    const handleSubmit = (event) => {
        //send the data
        event.preventDefault();
        if (titleError || title.length == 0)
            return props.showNotification({
                color: 'warning',
                message: "Title is required.",
                time: 5000
            });
        setProcessing(true);
        props.addKeepassDataAuto(title, notes, url, (err, response) => {
            setProcessing(false);
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 10000
                })

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });
            props.getKeepassDataWithUsers()
            props.closeModel();
        })

    }

    return (
        <GridItem xs={12}>
            <Card >
                <CardBody>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <GridItem xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="title-select-input">Title/User</InputLabel>
                                <Select
                                    labelId="title-select-input"
                                    id="title-select"
                                    value={title}
                                    onChange={handleTitle}
                                >
                                    {props.users.map(user => <MenuItem value={user}>{user}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="URL"
                                id="url"
                                inputProps={{
                                    value: url,
                                    onChange: (event) => setUrl(event.target.value),
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />

                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Notes"
                                id="notes"
                                inputProps={{
                                    value: notes,
                                    onChange: (event) => setNotes(event.target.value),
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />

                        </GridItem>
                        <GridItem xs={12} className={classes.logoHolder}>
                            <div className={classes.buttonHolder}>
                                <Button type="submit" color="primary"
                                    disabled={isProcessing}
                                >Add</Button>
                            </div>
                        </GridItem>
                    </form>
                </CardBody>
            </Card>
        </GridItem>
    )
}

const styles = makeStyles({});

const mapStateToProps = state => {
    return {
        users: state.keepass.users
    }
}

export default connect(mapStateToProps, actions)(AddKeepassAuto)
