import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";


import Button from '@material-ui/core/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        backgroundColor: '#000',
    },
    nextButton: {
        marginLeft: 'auto'
    },
    actionCards: {
        marginTop: 'auto'
    },
    disablePadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    dangerButton: {
        color: 'red',
    }
}));


const Step1 = props => {
    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false);
    const [googleGroup, setGoogleGroup] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [spreadsheetLink, setSpreadsheetLink] = useState('');


    const handleNext = () => {
        if (!nextDisabled)
            props.next(1, [googleGroup, userEmail, customerName, spreadsheetLink]);
    }

    useEffect(() => {
        if (props.workingCustomer) {
            setGoogleGroup(props.workingCustomer.step1.googleGroup);
            setUserEmail(props.workingCustomer.step1.customerEmail);
            setCustomerName(props.workingCustomer.step1.customerName);
            setSpreadsheetLink(props.workingCustomer.step1.spreadsheetLink);
        }
    }, [props.workingCustomer])

    const isEmail = email => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
    }

    const nextDisabled = !googleGroup || !userEmail || !customerName || !spreadsheetLink || !isEmail(googleGroup) || !isEmail(userEmail);

    return (
        <Card className={classes.root} disableSpacing>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>1</Avatar>
                }
                title="Step 1"
                subheader="Customer Details"
            />

            <CardContent classes={{ root: classes.disablePadding }}>
                <GridItem xs={12}>
                    <CustomInput labelText="Google group" id='google_group'
                        inputProps={{
                            value: googleGroup,
                            onChange: (event) => setGoogleGroup(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>

                <GridItem xs={12}>
                    <CustomInput labelText="Customer email" id='customer_email'
                        inputProps={{
                            value: userEmail,
                            onChange: (event) => setUserEmail(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>

                <GridItem xs={12} >
                    <CustomInput labelText="Customer name" id='customer_name'
                        inputProps={{
                            value: customerName,
                            onChange: (event) => setCustomerName(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>

                <GridItem xs={12}>
                    <CustomInput labelText="Spreadsheet" id='spreadsheet'
                        inputProps={{
                            value: spreadsheetLink,
                            onChange: (event) => setSpreadsheetLink(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>
            </CardContent>

            <CardActions disableSpacing classes={{ root: classes.actionCards }}>
                {props.next ? <Button variant="outlined" color="success" endIcon={<ChevronRight />} className={classes.nextButton} disabled={nextDisabled} onClick={handleNext}>Next</Button> : ''}
            </CardActions>

        </Card >

    )
}

export default Step1;
