import React, { useEffect, useRef, useState } from 'react';
import withBase from '../../hoc/withBase';

import axios from '../../axios';

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from '../../components/Card/CardBody';
import CustomInput from "../../components/CustomInput/CustomInput";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import { makeStyles } from "@material-ui/core/styles";



import { BACKEND } from '../../constants'


import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);

const TestMail = props => {
  const classes = useStyles();
  const [operatorId, setOperatorId] = useState('Soracom Operator Id');
  const [customerUserName, setCustomerUserName] = useState('Soracom Login Username');
  const [customerPass, setCustomerPass] = useState('Soracom Login Password');
  const [customerName, setCustomerName] = useState('Test Name');
  const [customerEmail, setCustomerEmail] = useState('');
  const [serverResponse, setServerResponse] = useState(null);
  const [emailError, setEmailError] = useState(true);

  const handleEmail = (event) => {
    setEmailError(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(event.target.value))
    setCustomerEmail(event.target.value)
  }

  const handleSubmit = async (event) => {
    //send the data
    event.preventDefault();
    if (emailError || operatorId.length === 0 || customerUserName.length === 0 || customerPass.length === 0 || customerName.length === 0)
      return props.showNotification({
        color: 'warning',
        message: "Please fill all the input to send the test mail.",
        time: 5000
      })
    setServerResponse(null);
    // make the call to the server
    try {
      const response = await axios.post(BACKEND.SEND_TEST_EMAIL.uri, {
        operatorId,
        customerUserName,
        customerPass,
        customerName,
        customerEmail
      });
      setServerResponse(response);
    } catch (e) {
      setServerResponse("Failed to get response from backend. Try Again.")
    }

  }


  return (
    <>
      <GridContainer>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <GridItem xs={12}>
            <CustomInput
              labelText="Customer Name"
              id="customerName"
              inputProps={{
                value: customerName,
                onChange: (event) => setCustomerName(event.target.value),
              }}
              formControlProps={{
                fullWidth: true,
                className: classes.root
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="Operator Id"
              id="operatorId"
              inputProps={{
                value: operatorId,
                onChange: (event) => setOperatorId(event.target.value),
              }}
              formControlProps={{
                fullWidth: true,
                className: classes.root
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <CustomInput
              labelText="Username"
              id="customerUserName"
              inputProps={{
                value: customerUserName,
                onChange: (event) => setCustomerUserName(event.target.value),
              }}
              formControlProps={{
                fullWidth: true,
                className: classes.root
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <CustomInput
              labelText="Password"
              id="customerPass"
              inputProps={{
                value: customerPass,
                onChange: (event) => setCustomerPass(event.target.value),
              }}
              formControlProps={{
                fullWidth: true,
                className: classes.root
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            customerEmail
            <CustomInput
              labelText="Send Test Email To:"
              id="customerEmail"
              inputProps={{
                value: customerEmail,
                onChange: handleEmail,
                error: emailError
              }}
              formControlProps={{
                fullWidth: true,
                className: classes.root
              }}
            />
          </GridItem>

          <GridItem xs={12} className={classes.logoHolder}>
            <div className={classes.buttonHolder}>
              <Button type="submit" variant="contained" color="primary"
              >Send Test Email</Button>
            </div>
          </GridItem>
        </form>

        <GridItem xs={12}>
          <Card >
            <CardBody if>
              <h3>Server Response:</h3>
              <pre>
                {serverResponse && (JSON.stringify(serverResponse.data, null, 2))}
              </pre>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer >
    </>
  )
}

export default withBase(TestMail);
