const AUTHENTICATED = 'authenticated';
const TOKEN = 'token';
const EXPIRY = 'expiry';
const USER = 'USER';


function getAuthenticated() {
    return localStorage.getItem(AUTHENTICATED) || false;
}

function setAuthenticated(authenticated) {
    authenticated ?
        localStorage.setItem(AUTHENTICATED, true) :
        localStorage.removeItem(AUTHENTICATED);
}

function getToken() {
    return localStorage.getItem(TOKEN);

}

function setToken(token) {
    token ?
        localStorage.setItem(TOKEN, token) :
        localStorage.removeItem(TOKEN);
}

function getExpiry() {
    return localStorage.getItem(EXPIRY);
}

function setExpiry(expiry) {
    expiry ?
        localStorage.setItem(EXPIRY, expiry) :
        localStorage.removeItem(EXPIRY);

}

export default {
    getAuthenticated,
    setAuthenticated,
    getToken,
    setToken,
    getExpiry,
    setExpiry,
}

