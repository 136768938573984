import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbars/Navbar';
import Footer from "../components/Footer/Footer.js";

import logo from '../assets/img/Thinxtra-Australia-Logo.png'
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";

import Snackbar from "../components/Snackbar/Snackbar"

import { WEBSITE_NAME } from '../constants';

import appRoutes from '../appRoutes';

const useStyles = makeStyles(styles);

export default (ChildComponent) => {

    const Base = (props) => {

        const classes = useStyles();

        const [mobileOpen, setMobileOpen] = React.useState(false);

        const handleDrawerToggle = () => {
            setMobileOpen(!mobileOpen);
        };

        const [notificationOpen, setNotificationOpen] = useState(false);
        const [notificationColor, setNotificationColor] = useState('success');
        const [notificatonMesssage, setNotificatonMesssage] = useState('');

        const showNotification = (notificatinProps) => {
            setNotificationColor(notificatinProps.color || 'success');
            setNotificatonMesssage(notificatinProps.message || '')
            setNotificationOpen(true);
            setTimeout(function () {
                setNotificationOpen(false);
            }, notificatinProps.time || 3000);
        }

        return (
            <>
                <Snackbar place='tr' color={notificationColor} message={notificatonMesssage} open={notificationOpen} />
                <div className={classes.wrapper}>
                    <Sidebar
                        routes={appRoutes}
                        logoText={WEBSITE_NAME}
                        logo={logo}
                        image={logo}
                        handleDrawerToggle={handleDrawerToggle}
                        open={mobileOpen}
                        color="blue"
                        bgColor="purple"
                    />
                    <div className={classes.mainPanel}>

                        <Navbar
                            routes={appRoutes}
                            handleDrawerToggle={handleDrawerToggle}
                            color="info"
                        />
                        <div className={classes.content}>
                            <div className={classes.container}>
                                <ChildComponent {...props} showNotification={showNotification} />
                            </div>
                        </div>
                        <Footer websiteName={WEBSITE_NAME} />
                    </div>
                </div>
            </>
        )

    }

    return Base;
}
