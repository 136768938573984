import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import moment from 'moment-timezone';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { capitalizeFirstLetter, nFormatter } from '../../helpers/helper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const getData = (dailyBillings, selectedLegends) => {
  const tmpObj = {};

  for (const dailyBilling of dailyBillings) {
    if (selectedLegends[dailyBilling.operatorId]) {
      if (!tmpObj[dailyBilling.operatorId]) {
        tmpObj[dailyBilling.operatorId] = []
      }
    } else {
      continue;
    }
    for (const bill of dailyBilling.billList) {
      tmpObj[dailyBilling.operatorId].push({
        x: moment(bill.date, 'YYYYMMDD').format('YYYY-MM-DD'),
        y: bill.tillDate
      })
    }
  }

  return {
    datasets: Object.keys(tmpObj).map((operatrorId) => {
      return {
        label: selectedLegends[operatrorId] ? capitalizeFirstLetter(selectedLegends[operatrorId].name.replace('soracom_', '').replace('@thinxtra.com', '')) : operatrorId,
        data: tmpObj[operatrorId],
        borderColor: selectedLegends[operatrorId].color,
        backgroundColor: selectedLegends[operatrorId].color
      }
    }).sort((a, b) => b.data.length - a.data.length)
  }
}

const DailyBillingsChart = props => {
  const { dailyBillings, legend } = props;


  //We get two things from the props daily billings and legend

  let chartToDisplay = '';
  if (dailyBillings.length && Object.keys(legend).length) {
    //using the legend get the selected legends
    const selectedLegends = Object.keys(legend).reduce((newDict, operatorId) => !legend[operatorId].disabled ? ({ ...newDict, [operatorId]: legend[operatorId] }) : newDict, {});
    //with the selected legends get the data to display
    const data = getData(dailyBillings, selectedLegends);
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        scales: {
          xAxes: {
            ticks: {
              maxTicksLimit: 30
            }
          }
        },
        datalabels: {
          display: Object.keys(selectedLegends).length < 5,
          color: "black",
          formatter: (obj) => nFormatter(obj.y),
          anchor: "end",
          offset: -20,
          align: "start"
        },
        title: {
          display: true,
          text: 'Daily Usage For Customer in USD',
        },
      },
    };
    chartToDisplay = (<Line options={options} data={data} plugins={[ChartDataLabels]} />)
  }

  return (
    <>
      {chartToDisplay}
    </>
  )
}

export default DailyBillingsChart;