import { KEEPASS_DATA_WITH_USERS, CLEAR_KEEPASS_DATA_WITH_USERS } from '../actionTypes/keepass';
import { BACKEND } from '../../constants';
import axios from '../../axios';

export const getKeepassDataWithUsers = (callback) => async (dispatch) => {
    //this shoud fetch the keepass data from the backend
    axios.get(BACKEND.LIST_KEEPASS.uri)
        .then(response => {
            dispatch({
                type: KEEPASS_DATA_WITH_USERS,
                payload: response.data
            })
        }).catch(err => {
            if (callback)
                callback(err)
        })
}

export const getDataForKeepass = (keepass_uuid, callback) => async (dispatch) => {
    //this should fetch more data for the selected keepass
    axios.get(BACKEND.KEEPASS_DETAILS.uri.replace(':?1', encodeURIComponent(keepass_uuid)))
        .then(response => {
            callback(null, response)
        }).catch(err => {
            callback(err)
        })
}

export const updateKeepassData = (keepass_uuid, userName, passWord, notes, url, callback) => async (dispatch) => {
    //this should update the keepass data
    axios.post(BACKEND.KEEPASS_DETAILS.uri.replace(':?1', encodeURIComponent(keepass_uuid)), {
        userName, passWord, notes, url
    }).then(response => {
        callback(null, response)
    }).catch(err => {
        callback(err)
    })
}

export const deleteKeepassData = (keepass_uuid, callback) => async (dispatch) => {
    //to delte the keepass data it should be deleted from the local as well as should attempt to delete the data from the server for the account user
    axios.delete(BACKEND.KEEPASS_DETAILS.uri.replace(':?1', encodeURIComponent(keepass_uuid)))
        .then(response => {
            callback(null, response)
        }).catch(err => {
            callback(err)
        })
}

export const addKeepassData = (title, userName, passWord, notes, url, callback) => async (dispatch) => {
    //should generate a unique uuid and add the data to the database
    //we may need to steal the generate uuid function from kdbxweb
    axios.post(BACKEND.LIST_KEEPASS.uri, {
        title, userName, passWord, notes, url
    }).then(response => {
        callback(null, response)
    }).catch(err => {
        callback(err)
    })
}

export const addKeepassDataAuto = (title, notes, url, callback) => async (dispatch) => {
    //find the user, check if the user already has billing user
    //if the billing user exist check the number of keys in it, if there is 2 keys already then delete the first one add a new key
    //if the user does not exist create one, add permissions and add a new key
    axios.post(BACKEND.ADD_KEEPASS_AUTO.uri, {
        title, notes, url
    }).then(response => {
        callback(null, response)
    }).catch(err => {
        callback(err)
    })
}

export const clearKeepassDataWithUsers = () => {
    return {
        type: CLEAR_KEEPASS_DATA_WITH_USERS,
        payload: null
    }
}
