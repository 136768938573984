import DashboardIcon from "@material-ui/icons/Dashboard";

import SoracomUsers from './views/SoracomUsers/SoracomUsers';
import SoracomSubscribers from './views/soracomSubscribers/SoracomSubscribers';
import LocalUsers from './views/LocalUsers/LocalUsers';
import LoginSignup from './views/LoginSignup/LoginSignup';
import Dashboard from './views/Dashboard/Dashboard';
import OngoingRegistraion from './views/CustomerRegistration/Ongoing';
import CompletedRegistraion from './views/CustomerRegistration/Completed';
import ImportFromKDBX from './views/ImportExports/ImportFromKDBX'
import ImportFromKDBXConfirm from './views/ImportExports/ImportFromKDBXConfirm'
import ExportFromKDBX from './views/ImportExports/ExportFromKDBX'
import KeepassList from './views/Keepass/KeepassList'
import RegistrationsAndActivations from './views/Reports/RegistrationsAndActivations'
import RegistrationsAndActivationsDelta from './views/Reports/RegistrationsAndActivationsDelta'
import TestMail from './views/TestMail/TestMail'

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import Person from "@material-ui/icons/Person";
import PeopleIcon from '@material-ui/icons/People';
import SimCardIcon from '@material-ui/icons/SimCard';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import PublishIcon from '@material-ui/icons/Publish';
import EmailIcon from '@material-ui/icons/Email';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Receipt from "@material-ui/icons/Receipt";
import SoracomInvoices from "./views/SoracomInvoices/SoracomInvoices";
import SoracomInvoicesDetails from "./views/SoracomInvoices/SoracomInvoicesDetails";
// import DescriptionIcon from '@mui/icons-material/Description';
// import AssessmentIcon from '@mui/icons-material/Assessment';

export default [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        display: true
    },
    {
        path: "/customer-registration",
        name: "New Customer",
        icon: PersonAddIcon,
        component: null,
        display: true,
        childrens: [
            {
                path: "/ongoing",
                name: "Ongoing",
                icon: HourglassEmptyIcon,
                component: OngoingRegistraion,
                display: true
            },
            {
                path: "/completed",
                name: "Completed",
                icon: HourglassFullIcon,
                component: CompletedRegistraion,
                display: true
            }
        ]
    },
    {
        path: "/soracom-users",
        name: "Soracom Users",
        icon: PeopleIcon,
        component: SoracomUsers,
        display: true
    },
    {
        path: "/soracom-subscribers",
        name: "Soracom Subscribers",
        icon: SimCardIcon,
        component: SoracomSubscribers,
        display: true
    },
    {
        path: "/soracom-invoices",
        name: "Soracom Invoices",
        icon: Receipt,
        component: SoracomInvoices,
        display: true
    },
    {
        path: "/soracom-invoices/:processId",
        name: "Soracom Invoices",
        icon: Receipt,
        component: SoracomInvoicesDetails,
        display: false
    },
    {
        path: "/users",
        name: "Users (Local)",
        icon: Person,
        component: LocalUsers,
        display: true
    },
    {
        path: "/keepass",
        name: "Keepass",
        icon: LockOpenIcon,
        component: KeepassList,
        display: true
    },
    {
        path: "/imp-exp",
        name: "Import Export",
        icon: ImportExportIcon,
        component: null,
        display: true,
        childrens: [
            {
                path: "/import",
                name: "Import KDBX",
                icon: SystemUpdateAltIcon,
                component: ImportFromKDBX,
                display: true
            },
            {
                path: "/export",
                name: "Export KDBX",
                icon: PublishIcon,
                component: ExportFromKDBX,
                display: true
            }
        ]
    },
    {
        path: "/reports",
        name: "Reports",
        icon: PublishIcon,
        component: null,
        display: true,
        childrens: [
            {
                path: "/registrations-and-activations",
                name: "Registrations & Activations",
                icon: PublishIcon,
                component: RegistrationsAndActivations,
                display: true
            },
            {
                path: "/delta-registrations-and-activations",
                name: "Registrations & Activations Delta",
                icon: PublishIcon,
                component: RegistrationsAndActivationsDelta,
                display: true
            },
        ]
    },
    {
        path: "/test-mail",
        name: "Test mail",
        icon: EmailIcon,
        component: TestMail,
        display: true
    },
    {
        path: "/login",
        name: "Login",
        icon: Person,
        component: LoginSignup,
        display: false
    },

];

export const otherValidRoutes = [{ path: '/imp-exp/import/confirm-import', component: ImportFromKDBXConfirm }
]