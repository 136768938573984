import { BACKEND } from '../../constants';
import axios from '../../axios';

export const getSoracomInvoicesProcess = (callback) => async (dispatch, getState) => {
    let response
    try {
        response = await axios.get(BACKEND.LIST_INVOICE_DOWNLOAD_PROCESS.uri);
        callback(null, response.data)
    } catch (e) {
        callback(e)
    }
}


export const getInvoiceDownloadProcessDetails = (id, callback) => async (dispatch) => {
    let response
    try {

        response = await axios.get(BACKEND.INVOICE_DOWNLOAD_PROCESS_DETAILS.uri.replace(':?1', id));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const getAllOperatorsAndNames = (callback) => async (dispatch) => {
    let response
    try {

        response = await axios.get(BACKEND.GET_OPERATORS_AND_NAMES.uri);
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const startSummaryDownload = (billingMonthYear, operatorId, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.START_INVOICE_DOWNLOAD_PROCESS.uri, {
            billingMonthYear, operatorId
        });
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}
