import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/CheckableTable';
import withBase from '../../hoc/withBase'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";

import PublishIcon from '@material-ui/icons/Publish';

import { makeStyles } from '@material-ui/styles'

import * as actions from '../../store/actions/importExport';
import { connect } from 'react-redux'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px'
    },
}));

const FormForExportKdbx = props => {
    const classes = useStyles();

    const [dbName, setDbName] = useState('');
    const [groupName, setGroupName] = useState('');
    const [password, setPassword] = useState('');

    const [isProcessing, setProcessing] = useState(false);

    const [dbNameError, setdbNameError] = useState(false);
    const [groupNameError, setGroupNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleDbNameEvent = (event) => {
        setDbName(event.target.value)
        setdbNameError(event.target.value.length === 0)
    }

    const handleGroupNameEvent = (event) => {
        setGroupName(event.target.value)
        setGroupNameError(event.target.value.length === 0)
    }

    const handlePasswordEvent = (event) => {
        setPassword(event.target.value)
        setPasswordError(event.target.value.length === 0)
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (dbName.length && groupName.length && password.length) {
            setProcessing(true)
            try {
                const message = props.handleExport(dbName, groupName, password);
                setProcessing(false);
                setDbName('')
                setGroupName('')
                setPassword('')
                setdbNameError(false)
                setGroupNameError(false)
                setPasswordError(false)
            } catch (err) {
                setProcessing(false)
            }
            props.setModalOpen(false);
        } else {
            props.showNotification({
                color: 'warning',
                message: "The fields cannot be empty",
                time: 3000
            })
        }


    }

    return (
        // display the add user buton towards the right of the screen
        <>
            <Modal
                className={classes.modal}
                open={props.modalOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                onClose={() => props.setModalOpen(false)}
                aria-labelledby="Export KDBX modal"
                aria-describedby="Export KDBX modal">
                <Fade in={props.modalOpen}>
                    <div className={classes.paper}>
                        <form onSubmit={handleFormSubmit}>
                            <Typography >Add soracom user</Typography>


                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="File name/Database name"
                                    inputProps={{
                                        value: dbName,
                                        onChange: handleDbNameEvent,
                                        error: dbNameError
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root
                                    }}

                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Group Name"
                                    inputProps={{
                                        value: groupName,
                                        onChange: handleGroupNameEvent,
                                        error: groupNameError
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root
                                    }}

                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Password"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root,
                                    }}
                                    inputProps={{
                                        value: password,
                                        onChange: handlePasswordEvent,
                                        error: passwordError
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} className={classes.logoHolder}>
                                <div className={classes.buttonHolder}>
                                    <Button type="submit" color="primary"
                                        disabled={isProcessing}
                                    >Generate</Button>
                                </div>
                            </GridItem>

                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    )

}

const ExportFromKDBX = props => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedUUID, setSelectedUUID] = React.useState([]);

    useEffect(() => {
        props.exportKeepass((error) => {
            if (error)
                return props.showNotification({
                    color: 'warning',
                    message: error.response.data.message,
                    time: 5000
                })
        });
    }, [])

    const headCells = [
        { id: 'uuid', label: 'UUID', isSearchable: true },
        { id: 'title', label: 'Title', isSearchable: true },
        { id: 'username', label: 'UserName', isSearchable: true },
        { id: 'notes', label: 'Notes', isSearchable: true },
        { id: 'created_on', label: 'Added On', isSearchable: false, type: 'Date' },
    ];

    const displayModel = (selectedRowsIndex) => {
        setSelectedUUID(selectedRowsIndex.map(selectedRows => props.keepass[selectedRows].uuid));
        setModalOpen(true);
    }

    const handleExport = (dbName, groupName, password) => {
        props.confirmExport(selectedUUID, dbName, groupName, password, (err) => {
            const blob = err.response.data;
            blob.text().then(data => {
                const message = JSON.parse(data);
                props.showNotification({
                    color: 'warning',
                    message: message.message,
                    time: 5000
                })
            });
        });
    }

    return (
        <>
            <FormForExportKdbx modalOpen={modalOpen} setModalOpen={setModalOpen} handleExport={handleExport} showNotification={props.showNotification} />
            <Table rows={props.keepass.map(keepassEntry => [keepassEntry.uuid, keepassEntry.fields.Title, keepassEntry.fields.UserName, keepassEntry.fields.Notes, new Date(keepassEntry.createdAt)])}
                headCells={headCells}
                mainAction={{
                    icon: PublishIcon, onClick: displayModel, title: 'Export Selected'
                }}
                name="Export to KDBX" />
        </>
    );
}

const styles = makeStyles({
});

const mapStateToProps = (state) => {
    return {
        keepass: state.importExport.export
    }
}

export default withBase(connect(mapStateToProps, actions)(ExportFromKDBX))