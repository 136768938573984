import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles'


import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@material-ui/icons/Save';
import { getRandomString } from '../../helpers/helper'


import * as actions from '../../store/actions/keepass';

const KeepassDetails = props => {
    const classes = styles();
    const openedKeepass = props.openedKeepass;

    const [keepassDetails, setKeepassDetails] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [randomString] = useState(() => getRandomString(5));
    const [enteredRandomString, setEnteredRandomString] = useState('');

    const [isProcessing, setProcessing] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [url, setUrl] = useState('');
    const [notes, setNotes] = useState('');

    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleUserName = (event) => {
        setUserNameError(!event.target.value.length)
        setUserName(event.target.value)
    }

    const handlePassword = (event) => {
        setPasswordError(!event.target.value.length)
        setPassword(event.target.value)
    }

    const handleSubmit = event => {
        if (event)
            event.preventDefault();

        if (userNameError || passwordError || !userName.length || !password.length)
            return props.showNotification({
                color: 'warning',
                message: 'Both username and passwords are required.',
                time: 5000
            });

        props.updateKeepassData(keepassDetails.keepassData.uuid, userName, password, notes, url, (err, response) => {
            if (err) return props.showNotification({
                color: 'warning',
                message: err.response.data.message,
                time: 10000
            });

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });

            props.getKeepassDataWithUsers();
            props.setDetailKeepassModalOpen(false);
        })
    }

    const handleDelete = () => {
        props.deleteKeepassData(keepassDetails.keepassData.uuid, (err, response) => {
            if (err) return props.showNotification({
                color: 'warning',
                message: err.response.data.message,
                time: 10000
            });

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });

            props.getKeepassDataWithUsers();
            props.setDetailKeepassModalOpen(false);
        });
    }

    useEffect(() => {
        //retrieve the details
        props.getDataForKeepass(openedKeepass, (err, response) => {
            if (err) {
                props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 10000
                });
                props.setDetailKeepassModalOpen(false);
            } else {
                setKeepassDetails(response.data);
                setUserName(response.data.keepassData.fields.UserName);
                setPassword(response.data.keepassData.fields.Password);
                setUrl(response.data.keepassData.fields.URL);
                setNotes(response.data.keepassData.fields.Notes);
            }
        });

        return () => {
            setKeepassDetails(null);
            setEditMode(false);
            setProcessing(false);
            setUserName('');
            setPassword('');
            setUrl('');
            setNotes('');
            setUserNameError(false);
            setPasswordError(false);
        }
    }, []) //only when the component is mounted

    const keepassForm = keepassDetails ? (<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>

        <GridItem xs={12}>
            <CustomInput
                labelText="Title"
                id="title"
                inputProps={{
                    value: keepassDetails.keepassData.fields.Title,
                    disabled: true
                }}
                formControlProps={{
                    fullWidth: true,
                    className: classes.root
                }}
            />
        </GridItem>

        <GridItem xs={12}>
            <CustomInput
                labelText="User name"
                id="userName"
                inputProps={{
                    value: userName,
                    onChange: handleUserName,
                    error: userNameError,
                    disabled: !editMode
                }}
                formControlProps={{
                    fullWidth: true,
                    className: classes.root
                }}
            />
        </GridItem>

        <GridItem xs={12}>
            <CustomInput
                labelText="Password"
                id="password"
                inputProps={{
                    value: password,
                    onChange: handlePassword,
                    error: passwordError,
                    disabled: !editMode
                }}
                formControlProps={{
                    fullWidth: true,
                    className: classes.root
                }}
            />

        </GridItem>

        <GridItem xs={12}>
            <CustomInput
                labelText="URL"
                id="url"
                inputProps={{
                    value: url,
                    onChange: (event) => setUrl(event.target.value),
                    disabled: !editMode
                }}
                formControlProps={{
                    fullWidth: true,
                    className: classes.root
                }}
            />

        </GridItem>

        <GridItem xs={12}>
            <CustomInput
                labelText="Notes"
                id="notes"
                inputProps={{
                    value: notes,
                    onChange: (event) => setNotes(event.target.value),
                    disabled: !editMode
                }}
                formControlProps={{
                    fullWidth: true,
                    className: classes.root
                }}
            />

        </GridItem>
    </form>) : (<CircularProgress />);

    const realatedUserDetails = keepassDetails ? (<TableContainer component={Paper}>
        <Table aria-label="Associated User table">
            <TableBody>
                <TableRow>
                    <TableCell>Assoc user</TableCell>
                    <TableCell>{keepassDetails.associatedUser.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Operator Id</TableCell>
                    <TableCell>{keepassDetails.associatedUser.operatorId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>{keepassDetails.associatedUser.userName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Total Subscribers</TableCell>
                    <TableCell>{keepassDetails.associatedSubscribersCount}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>{new Date(keepassDetails.associatedUser.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>) : (<CircularProgress />);

    const buttonsDisplay = editMode ? (<>
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSubmit} disabled={isProcessing}>Update</Button>
        <Button variant="contained" color="danger" startIcon={<DeleteOutlineIcon />} onClick={() => setDeleteMode(true)} disabled={isProcessing}>Delete</Button>
    </>) : (<>
        <Button variant="contained" color="default" startIcon={<EditIcon />} disabled={isProcessing} onClick={() => setEditMode(true)}>Edit</Button>
        <Button variant="contained" color="primary" startIcon={<CloseIcon />} onClick={() => props.setDetailKeepassModalOpen(false)} >Close</Button>
    </>)

    return (
        <GridItem xs={12}>
            <Card >
                <CardBody>
                    {deleteMode ? (
                        <GridItem xs={12}>
                            <Typography variant='body1'>Please type <b>`{randomString}`</b> to confirm the deletion.</Typography>
                            <Typography variant='body2'>Please note that this action deletes the data from this platform but will not delete from the Soracom portal. <br />If you need to delete please do so manually manually by logging in to the Soracom portal.</Typography>

                            <CustomInput
                                labelText="Delete Confirmation"
                                id="deleteConfirmation"
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                                inputProps={{
                                    value: enteredRandomString,
                                    onChange: (event) => setEnteredRandomString(event.target.value),
                                }}
                            />
                            <Button variant="contained" color="danger" startIcon={<DeleteOutlineIcon />} onClick={() => {
                                //Check if the random string and the entered random string match
                                //if not display warning and exit out of the deletion mode
                                if (enteredRandomString === randomString) {
                                    handleDelete();
                                } else {
                                    props.showNotification({
                                        color: 'error',
                                        message: `The input does not match. Deletion aborted.`,
                                        time: 5000
                                    });
                                }
                                setDeleteMode(false);
                            }} disabled={isProcessing}>Delete</Button>
                        </GridItem>
                    ) : (
                        <GridItem xs={12} className={classes.buttonHolder}>
                            <div className={classes.buttonHolder}>
                                {buttonsDisplay}
                            </div>
                        </GridItem>
                    )}

                    <Typography variant='h5'>Keepass Details</Typography>
                    {keepassForm}
                    <Typography variant='h5'>Related Soracom User</Typography>
                    {realatedUserDetails}
                </CardBody>
            </Card>
        </GridItem>
    )
}

const styles = makeStyles({
    buttonHolder: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export default connect(null, actions)(KeepassDetails);
