import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux';

import * as actions from '../../store/actions/localUsers';

import AddUser from './AddUser'

import SimpleTabs from './SimpleTabs';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid    #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: '50vw',
        maxWidth: '90vw',
        minHeight: '50vh',
        maxHeight: '90vh',
        overflowY: 'scroll'
    },

}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Add soracom user model"
            aria-describedby="Add soracom user">
            <Fade in={props.modalOpen}>
                <div className={classes.paper}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
}

const LocalUsers = (props) => {
    const classes = useStyles();

    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [detailedUserModalOpen, setDetailedUserModalOpen] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        props.getLocalUsers();
    }, [])

    const headCells = [
        { id: 'first_name', label: 'First name', isSearchable: true },
        { id: 'last_name', label: 'Last name', isSearchable: true },
        { id: 'email', label: 'Email', isSearchable: true },
        { id: 'source', label: 'User type', isSearchable: true },
        { id: 'userLevel', label: 'User level', isSearchable: true },
        { id: 'createdAt', label: 'Created at', isSearchable: false },
    ];

    const handleClick = (event, row) => {
        setSelectedUser({
            first_name: row[0],
            last_name: row[1],
            email: row[2],
            source: row[3],
            userLevel: row[4],
        });
        setDetailedUserModalOpen(true)
    }

    return (
        <>
            <SimpleModal modalOpen={addUserModalOpen} setModalOpen={setAddUserModalOpen}>
                <AddUser setModalOpen={setAddUserModalOpen} showNotification={props.showNotification} />
            </SimpleModal>

            <SimpleModal modalOpen={detailedUserModalOpen} setModalOpen={setDetailedUserModalOpen}>
                <SimpleTabs showNotification={props.showNotification} selectedUser={selectedUser}
                    closeModel={() => setDetailedUserModalOpen(false)} />
            </SimpleModal>

            <Table rows={props.users} headCells={headCells} onClick={handleClick} name="Users" />
            <Fab size="small" className={classes.fab} color="primary" aria-label="add" onClick={() => setAddUserModalOpen(true)}>
                <AddIcon />
            </Fab>
        </>
    )
}
const mapStateToProps = State => {
    return {
        auth: State.auth,
        users: State.users
    }
}
export default withBase(connect(mapStateToProps, actions)(LocalUsers));
