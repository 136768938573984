import React, { useState, useEffect } from 'react'
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import CustomInput from "../../components/CustomInput/CustomInput";
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button/';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { connect } from 'react-redux';

import * as actions from '../../store/actions/invoiceDownloads'


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '90vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px',
        maxHeight: '100%',
        overflow: 'scroll'
    },
}));

const SoracomInvoices = (props) => {
    const [invoiceDownloadsProcess, setInvoiceDownloadsProcess] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const [invoiceDate, setInvoiceDate] = useState(() => {
        const date = new Date();
        return date.getMonth() ? date.getFullYear() * 1e2 + date.getMonth() : (date.getFullYear() - 1) * 1e2 + 12;

    });

    const [operatorsAndNames, setOperatorsAndNames] = useState([]);
    const [selectAllOperator, setSelectAllOperator] = useState(true);
    const [selectedOperator, setSelectedOperator] = useState([]);

    const [isProcessing, setProcessing] = useState(false);

    useEffect(() => {
        props.getSoracomInvoicesProcess((err, data) => {
            if (!err) {
                setInvoiceDownloadsProcess(data);
                return;
            }
            //TODO Show error loading data message
        });
        props.getAllOperatorsAndNames((err, data) => {
            if (!err) {
                setOperatorsAndNames(data.data);
            }
        })

        return () => {
            setInvoiceDownloadsProcess([]);
            setOperatorsAndNames([]);
        }
    }, [])

    const classes = useStyles();

    const headCells = [
        { id: 'id', label: '#', isSearchable: false },
        { id: 'invoiceDate', label: 'Invoice Date', isSearchable: false },
        { id: 'downloadStatus', label: 'Process Status', isSearchable: false },
        { id: 'completedDownload', label: 'Completed', isSearchable: false },
        { id: 'errorDownload', label: 'Errors', isSearchable: false },
        { id: 'googleDriveUpload', label: 'Drive Uploaded', isSearchable: false },
        { id: 'totalAmount', label: 'Total Amount', isSearchable: false },
        { id: 'triggeredBy', label: 'Type', isSearchable: false },
        { id: 'createdAt', label: 'Created', isSearchable: false, type: 'Date' }
    ];

    const handleInvoiceDateEvent = (event) => {
        console.log(event.target.value);
        const isInvoiceDateError = event.target.value.length !== 6
        console.log(isInvoiceDateError)
        if (!isInvoiceDateError) {
            //get last two values
            const lastTwo = +event.target.value.substr(4);
            const year = (+event.target.value - lastTwo);
            const reminder = lastTwo % 13;
            console.log(reminder, year);
            if (reminder) {
                setInvoiceDate(year + reminder)
            } else if (lastTwo === 13) {
                setInvoiceDate(year + 100 + 1)
            } else {
                setInvoiceDate(year - 100 + 12)
            }
        }
    }

    const handleToggle = (value) => () => {
        const currentIndex = selectedOperator.indexOf(value);
        const newChecked = [...selectedOperator];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedOperator(newChecked);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (selectAllOperator || selectedOperator.length) {
            setProcessing(true)

            props.startSummaryDownload(invoiceDate, selectAllOperator ? [] : selectedOperator, (err, data) => {
                if (err) {
                    props.showNotification({
                        color: 'danger',
                        message: err.response && err.response.data ? err.response.data.message : 'Please refresh!',
                        time: 3000
                    })
                    return
                } else {
                    props.showNotification({
                        color: 'success',
                        message: data.data.message,
                        time: 3000
                    })
                }
                setProcessing(false);
                setModalOpen(false);

            });
            props.getSoracomInvoicesProcess((err, data) => {
                if (!err) {
                    setInvoiceDownloadsProcess(data);
                    return;
                }
            });

        } else {
            props.showNotification({
                color: 'warning',
                message: "Please check your selections.",
                time: 3000
            })
        }
    }

    const startUsageSummaryDownloadsForm = (<Modal
        className={classes.modal}
        open={modalOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        onClose={() => setModalOpen(false)}
        aria-labelledby="Start Usage Summary Download model"
        aria-describedby="Start Usage Summary Download">
        <Fade in={modalOpen}>
            <div className={classes.paper}>
                <form onSubmit={handleFormSubmit}>
                    <Typography >Start Usage Summary Download</Typography>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText="Invoice Date(YYYYMM) *"
                            inputProps={{
                                value: invoiceDate,
                                onChange: handleInvoiceDateEvent,
                                type: 'number'
                            }}
                            formControlProps={{
                                fullWidth: true,
                                className: classes.root
                            }}

                        />
                    </GridItem>

                    <GridItem xs={12}>
                        <FormControlLabel control={<Switch
                            checked={selectAllOperator}
                            onChange={(event) => setSelectAllOperator(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label={`All Subscribers ( Total count: ${operatorsAndNames.length} subscribers.)`} />

                    </GridItem>
                    {selectAllOperator ? '' : (

                        <GridItem xs={12}>
                            <List className={classes.root}>
                                {operatorsAndNames.map((value) => {
                                    const labelId = `subscriber-id-${value.operatorId}`;
                                    return (
                                        <ListItem key={value.operatorId} role={undefined} dense button onClick={handleToggle(value.operatorId)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedOperator.indexOf(value.operatorId) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${value.name} [${value.operatorId}]`} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </GridItem>

                    )}
                    <GridItem xs={12} className={classes.logoHolder}>
                        <div className={classes.buttonHolder}>
                            <Button type="submit" color="primary" variant='outlined'
                                name='Submit'
                                disabled={isProcessing}
                            >Start</Button>
                        </div>
                    </GridItem>

                </form>
            </div>
        </Fade>
    </Modal>)

    return (
        <>
            <Fab className={classes.fab} size="small" color="primary" aria-label="add" onClick={() => setModalOpen(true)}>
                <AddIcon />
            </Fab>
            {startUsageSummaryDownloadsForm}
            <Table rows={invoiceDownloadsProcess.map(process => [
                process._id, process.invoiceDate, process.downloadStatus,
                process.completedDownload.length, process.errorDownload.length,
                Object.keys(process.googleDriveUpdate || {}).length ? 'Yes' : 'No',
                process.totalAmount.toFixed(4), process.triggeredBy, new Date(process.createdAt)
            ])} headCells={headCells} order="desc" orderBy="invoiceDate" onClick={(event, row) =>
                props.history.push(`soracom-invoices/${row[0]}`)} name="Soracom Invoices" />
        </>
    )
}
export default withBase(connect(null, actions)(SoracomInvoices));
