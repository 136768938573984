import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, fade } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import SearchIcon from '@material-ui/icons/Search';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const TableWithExternalSearchAndPagination = (props) => {

  const toolbarStylesClasses = useToolbarStyles();
  const classes = useStyles();

  const { rows, totalRows, headCells, onChangeGetData } = props;


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [searchBy, setSearchBy] = useState('search-everywhere');
  const [searchTerm, setSearchTerm] = useState('');
  const [timer, setTimer] = useState(null);

  const [dateCells] = useState(headCells.reduce((allDates, headCell, key) => {
    if (headCell.type === 'Date') {
      return {
        ...allDates,
        [key]: headCell.id
      }
    }
    return allDates;
  }, {}));

  const hadleSearchTermChange = (event) => {
    // Only search after 3 seconds
    const value = event.target.value;
    clearTimeout(timer);
    setSearchTerm(value);
    setTimer(setTimeout(() => {
      if (onChangeGetData) onChangeGetData(page, rowsPerPage, searchBy, value);
    }, 1500));
  }



  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
    if (onChangeGetData) onChangeGetData(page, rowsPerPage, searchBy, event.target.value);
  };

  const handleChangeRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (onChangeGetData) onChangeGetData(0, parseInt(event.target.value, 10), searchBy, searchTerm);
  };

  const handlePageChange = (event, newValue) => {
    setPage(newValue);
    if (onChangeGetData) onChangeGetData(newValue, rowsPerPage, searchBy, searchTerm);
  };

  let toolbar;

  if (props.noSearch) {
    toolbar = (<Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div" noWrap>{props.name}</Typography>
    </Toolbar>)
  } else {
    toolbar = (<Toolbar className={toolbarStylesClasses.root}>
      <Typography className={toolbarStylesClasses.title} variant="h6" id="tableTitle" component="div" noWrap>{props.tableName}</Typography>
      <div className={toolbarStylesClasses.search}>
        <div className={toolbarStylesClasses.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: toolbarStylesClasses.inputRoot,
            input: toolbarStylesClasses.inputInput,
          }}
          value={props.searchTerm}
          onChange={hadleSearchTermChange}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      <FormControl className={toolbarStylesClasses.formControl}>
        <Select
          value={searchBy}
          onChange={handleSearchByChange}
        >
          <MenuItem key='search-everywhere' value='search-everywhere'>Everywhere</MenuItem>
          {
            headCells.map((headCell, index) => {
              if (headCell.isSearchable)
                return <MenuItem key={`search-by-${index}-${headCell.id}`} value={headCell.id}>{headCell.label}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Toolbar >)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>

        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div" noWrap>{props.name}</Typography>
        </Toolbar>

        {toolbar}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align='left'
                    padding='default'
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      style={{
                        cursor: props.onClick ? 'pointer' : 'inherit'
                      }}
                      onClick={(event) => { if (props.onClick) props.onClick(event, row) }}
                      tabIndex={-1}
                      key={index}
                    >
                      {row.map((el, key) => <TableCell align="left" key={`${index}-${key}`}>{dateCells[key] ? `${el.toLocaleDateString('en-GB')} ${String(el.getHours()).padStart(2, '0')}:${String(el.getMinutes()).padStart(2, '0')}` : el}</TableCell>)}
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[100, 200, 300, 400, 500]}
          rowsPerPage={rowsPerPage}
          count={totalRows}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPageChange}
        />
      </Paper>
    </div>
  );
}

TableWithExternalSearchAndPagination.propTypes = {
  rows: PropTypes.array.isRequired,
  totalRows: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  noSearch: PropTypes.bool,
  onChangeGetData: PropTypes.func,
  onClick: PropTypes.func
};

export default TableWithExternalSearchAndPagination;
