import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        backgroundColor: '#000',
    },
    nextButton: {
        marginLeft: 'auto'
    },
    actionCards: {
        marginTop: 'auto'
    },
    disablePadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    inline: {
        display: 'inline',
    },
    dangerButton: {
        color: 'red',
    }
}));


const Step2 = props => {
    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false);
    const [formFilled, setFormFilled] = useState(false);

    const handleNext = () => {
        if (formFilled)
            props.next(2, [formFilled]);
    }

    useEffect(() => {
        if (props.workingCustomer && props.workingCustomer.step2)
            setFormFilled(props.workingCustomer.step2.formFilledConfirmation || false)
    }, [props.workingCustomer]);

    return (

        <Card className={classes.root} disableSpacing>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>2</Avatar>
                }
                title="Step 2"
                subheader="Generate User"
                action={
                    <Button variant="outlined" classes={{ root: classes.dangerButton }} aria-label="delete" onClick={props.deleteRegistration}>
                        Delete <DeleteForeverIcon />
                    </Button>
                }
            />

            <CardContent>
                <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar><LockOpenIcon /></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Create keepass password"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        Google group
                                    </Typography>
                                    {` — ${props.workingCustomer.step1.googleGroup}`}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar><AssignmentIcon /></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Spreadsheet with needed information."
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        Spreadsheet
              </Typography>
                                    {' — '} <a href={`${props.workingCustomer.step1.spreadsheetLink}`} target="_blank">Click to open</a>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Create customer acount in soracom"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        Soracom link
              </Typography>
                                    {' — '} <a href='http://google.com' target="_blank">Click to open</a>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formFilled}
                                    onChange={(event) => setFormFilled(event.target.checked)}
                                    color="primary"
                                />
                            }
                            label="The form has been filled."
                        />
                    </ListItem>
                </List>


            </CardContent>

            <CardActions disableSpacing classes={{ root: classes.actionCards }}>
                {props.prev ? <Button variant="outlined" color="success" startIcon={<ChevronLeft />} onClick={props.prev}>Previous</Button> : ''}
                {props.next ? <Button variant="outlined" color="success" endIcon={<ChevronRight />} className={classes.nextButton} onClick={handleNext} disabled={!formFilled}>Next</Button> : ''}
            </CardActions>

        </Card >
    )
}

export default Step2;
