import React, { useState, useEffect } from 'react';
import axios from '../../axios'
import withBase from '../../hoc/withBase'
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles'

//import the required componenents
import GridItem from "../../components/Grid/GridItem";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import * as actions from '../../store/actions/importExport';

import { connect } from 'react-redux'

import Snackbar from "../../components/Snackbar/Snackbar"

import { DropzoneArea } from 'material-ui-dropzone';

const ImportFromKDBX = props => {

    const classes = styles();
    const [groupName, setGroupName] = useState('');
    const [databasePassword, setDatabasePassword] = useState('');
    const [uploadFile, setUploadFile] = useState(null);

    const [inputGroupNameError, setInputGroupNameError] = useState(false);
    const [inputDatabasePasswordError, setInputDatabasePasswordError] = useState(false);

    const [isProcessing, setProcessing] = useState(false);

    const handleGroupname = (event) => {
        setInputGroupNameError(!Boolean(event.target.value.length))
        setGroupName(event.target.value)
    }

    const handleDatabasePassword = (event) => {
        setInputDatabasePasswordError(!Boolean(event.target.value.length))
        setDatabasePassword(event.target.value)
    }

    useEffect(() => {
        if (Object.keys(props.keepass).length != 0)
            props.history.push('/imp-exp/import/confirm-import')
    }, [props.keepass])

    const handleSubmit = async (event) => {
        event.preventDefault();
        // setProcessing(true);

        props.postDatabaseFile(uploadFile, groupName, databasePassword, (error) => {
            //this will be called only if the error ocurrs
            if (error.response && error.response.status === 400)
                props.showNotification({
                    color: 'warning',
                    message: error.response.data.message,
                    time: 5000
                })
        })
    }



    return (
        <>
            <Typography variant="h4">Please select the KDBX databse file.</Typography>
            <Typography variant="subtitle1">Please drag and drop the keepass backup file here if you want to import the data from the keepass
            file to the database. Please provide the password to unlock the keepass file. Please provide the group name from where you
            want to import the data.</Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <DropzoneArea
                    onChange={(loadedFiles) => setUploadFile(loadedFiles[0])}
                    onDelete={() => setUploadFile(null)}
                    acceptedFiles={['.kdbx']}
                    filesLimit={1}
                    dropzoneText={`Click or drag and drop file here to upload the KDBX file.`}
                    showFileNames={true}
                />
                <GridItem xs={12}>
                    <CustomInput
                        labelText="Groupname"
                        id="groupName"
                        inputProps={{
                            value: groupName,
                            onChange: handleGroupname,
                            error: inputGroupNameError,
                        }}
                        formControlProps={{
                            fullWidth: true,
                            className: classes.root
                        }}

                    />
                </GridItem>

                <GridItem xs={12}>
                    <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                            fullWidth: true,
                            className: classes.root,
                        }}
                        inputProps={{
                            type: "password",
                            value: databasePassword,
                            onChange: handleDatabasePassword,
                            error: inputDatabasePasswordError
                        }}
                    />
                </GridItem>
                <GridItem xs={12} className={classes.logoHolder}>
                    <div className={classes.buttonHolder}>
                        <Button type="submit" color="primary"
                            disabled={isProcessing}
                        >Import</Button>
                    </div>
                </GridItem>
            </form>
        </>
    );
}

const styles = makeStyles({
});

const mapStateToProps = (state) => {
    return {
        keepass: state.importExport.import
    }
}

export default withBase(connect(mapStateToProps, actions)(ImportFromKDBX))