import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import appRoutes, { otherValidRoutes } from './appRoutes';


const App = (props) => {


  return (
    <Switch>
      {appRoutes.map((route, index) => route.childrens ? route.childrens.map((children, index) => <Route key={`key-route-child-${index}`} exact path={`${route.path}${children.path}`} component={children.component} />) : <Route key={`key-route-${index}`} exact path={route.path} component={route.component} />)}
      {otherValidRoutes.map((route, index) => <Route key={`key-route-other-${index}`} exact path={route.path} component={route.component} />)}
      <Redirect from='/' to='/dashboard' />
    </Switch>
  );
}

export default App;
