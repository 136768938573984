import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers/combineReducers';
import reduxThunk from 'redux-thunk';

import localStorageService from '../service/localStorageService';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, {
    auth: {
        authenticated: localStorageService.getAuthenticated(),
        errorMessage: '',
        token: localStorageService.getToken(),
        user: {},
        expiresOn: localStorageService.getExpiry()
    }
}, composeEnhancers(applyMiddleware(reduxThunk)))

export default store;
