import React, { useState, useEffect } from 'react'
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase';

import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { connect } from 'react-redux';

import * as actions from '../../store/actions/sorcomSubscribers'

import SoracomSubscriberDetails from './SoracomSubscriberDetails';
import TableWithExternalSearchAndPagination from '../../components/Table/TableWithExternalSearchAndPagination';


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px'
    },
}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Soracom user details"
            aria-describedby="Soracom user details">
            <Fade in={props.modalOpen}>
                <div className={classes.paper} style={{
                    width: '90vw',
                    minHeight: '50vh',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
}

const SoracomSubscribers = (props) => {

    const [soracomSubscriberDetailsModelOpen, setSoracomSubscriberDetailsModelOpen] = useState(false);
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const headCells = [
        { id: 'imsi', label: 'IMSI', isSearchable: true },
        { id: 'msisdn', label: 'MSISDN', isSearchable: true },
        { id: 'operatorId', label: 'Operator Id', isSearchable: true },
        { id: 'simId', label: 'Sim Id', isSearchable: true },
        { id: 'moduleType', label: 'Module Type', isSearchable: true },
        { id: 'iccid', label: 'ICCID', isSearchable: true },
        { id: 'serialNumber', label: 'Serial Number', isSearchable: true }
    ];

    const handleClick = (event, row) => {
        setSelectedSubscriber(row);
        setSoracomSubscriberDetailsModelOpen(true);
    }

    useEffect(() => {
        props.getSoracomSubscribers();
    }, []);

    return (
        <>
            <SimpleModal modalOpen={soracomSubscriberDetailsModelOpen} setModalOpen={setSoracomSubscriberDetailsModelOpen}>
                <SoracomSubscriberDetails selectedSubscriber={selectedSubscriber} setModalOpen={setSoracomSubscriberDetailsModelOpen} showNotification={props.showNotification} />
            </SimpleModal>
            <TableWithExternalSearchAndPagination rows={props.soracomSubscribers[0]} totalRows={props.soracomSubscribers[1]} headCells={headCells} onChangeGetData={props.getSoracomSubscribers} onClick={handleClick} name="Soracom Subscribers" />
        </>
    )
}
const mapStateToProps = State => {
    return {
        auth: State.auth,
        soracomSubscribers: State.soracomSubscribers
    }
}
export default withBase(connect(mapStateToProps, actions)(SoracomSubscribers));
