export const BACKEND = {
    BASE: process.env.NODE_ENV === 'production' ? 'https://backend.soracom.thinxtra.com' : 'http://localhost:8080',
    LOGIN: {
        uri: '/auth/login',
        method: 'POST'
    },
    REFRESH_TOKEN: {
        uri: '/auth/refresh-token',
        method: 'POST'
    },
    DASHBOARD: {
        uri: '/api/v1/dashboard',
        method: 'GET'
    },
    DASHBOARD_OPERATORS: {
        uri: '/api/v1/operators/all',
        method: 'GET'
    },
    DASHBOARD_DAILY_BILLINGS: {
        uri: '/api/v1/dashboard/daily-billings',
        method: 'GET'
    },
    DASHBOARD_MONTHLY_BILLINGS: {
        uri: '/api/v1/dashboard/monthly-billings',
        method: 'GET'
    },
    DASHBOARD_SUBSCRIBERS_LOGS: {
        uri: '/api/v1/dashboard/subscribers-logs',
        method: 'GET'
    },
    DASHBOARD_CURRENT_SUBSCRIBER_STATUS: {
        uri: '/api/v1/dashboard/current-subscriber-status',
        method: 'GET'
    },
    ON_REGISTRATION_PROCESS: {
        uri: '/api/v1/registration?page=:?1',
        method: 'GET'
    },
    START_REGISTRATION: {
        uri: '/api/v1/registration',
        method: 'POST'
    },
    REGISTRATION_STEP: {
        uri: '/api/v1/registration/:?1/:?2',
        method: 'POST'
    },
    REGISTRATION_DETAILS: {
        uri: '/api/v1/registration/:?1',
        method: 'GET'
    },
    COMPLETED_REGISTRATION: {
        uri: '/api/v1/registration-completed?page=:?1',
        method: 'GET'
    },
    ADD_SORACOM_USER: {
        uri: '/api/v1/soracom_user',
        method: 'POST'
    },
    ADD_SORACOM_USER_CREDENTIALS: {
        uri: '/api/v1/soracom_user',
        method: 'PATCH'
    },
    REMOVE_SORACOM_USER: {
        uri: '/api/v1/soracom_user',
        method: 'DELETE'
    },
    LIST_SORACOM_USER: {
        uri: '/api/v1/soracom_user',
        method: 'GET'
    },
    SORACOM_USER_DETAILS: {
        uri: '/api/v1/soracom_user/:?1',
        method: 'GET'
    },
    SORACOM_USER_SUBSCRIBERS: {
        uri: '/api/v1/soracom_user/:?1/soracom-subscribers',
        method: 'GET'
    },
    LIST_SORACOM_SUBSCRIBERS: {
        uri: '/api/v1/soracom-subscribers',
        method: 'GET'
    },
    SORACOM_SUBSCRIBER_DETAILS: {
        uri: '/api/v1/soracom-subscribers/:?1',
        method: 'GET'
    },
    FIND_SUBSCRIBERS: {
        uri: '/api/v1/soracom_subscribers',
        method: 'GET'
    },
    LIST_INVOICE_DOWNLOAD_PROCESS: {
        uri: '/api/v1/invoice-downloads',
        method: 'GET'
    },
    START_INVOICE_DOWNLOAD_PROCESS: {
        uri: '/api/v1/invoice-downloads',
        method: 'POST'
    },
    GET_OPERATORS_AND_NAMES: {
        uri: '/api/v1/operators',
        method: 'GET'
    },
    INVOICE_DOWNLOAD_PROCESS_DETAILS: {
        uri: '/api/v1/invoice-downloads/:?1',
        method: 'GET'
    },
    INVOICES_FOR_OPERATORID: {
        uri: '/api/v1/invoice-downloads/:?1/invoices',
        method: 'GET'
    },
    DELETE_INVOICE_DOWNLOAD_PROCESS: {
        uri: '/api/v1/invoice-downloads/:?1',
        method: 'DELETE'
    },
    LIST_LOCAL_USERS: {
        uri: '/api/v1/local-users',
        method: 'GET'
    },
    LOCAL_USER_DETAILS: {
        uri: '/api/v1/local-users/:?1',
        method: 'GET'
    },
    IMPORT_KEEPASS_DATABASE: {
        uri: '/api/v1/import-database',
        method: 'POST'
    },
    IMPORT_KEEPASS_DATABASE_FINAL: {
        uri: '/api/v1/import-finalize',
        method: 'POST'
    },
    EXPORT_KEEPASS_DATABASE: {
        uri: '/api/v1/export-database',
        method: 'get'
    },
    LIST_KEEPASS: {
        uri: '/api/v1/keepasses',
        method: 'get'
    },
    ADD_KEEPASS_AUTO: {
        uri: '/api/v1/keepasses/add',
        method: 'POST'
    },
    KEEPASS_DETAILS: {
        uri: '/api/v1/keepasses/:?1',
        method: 'GET'
    },
    REGISTRATIONS_ACTIVATIONS_REPORT_DELTA: {
        uri: '/api/v1/reports/registrationsAndActivationsBetweenDates',
        method: 'GET'
    },
    REGISTRATIONS_ACTIVATIONS_REPORT: {
        uri: '/api/v1/reports/registrationsAndActivations',
        method: 'GET'
    },
    SEND_TEST_EMAIL: {
        uri: '/api/v1/sendTestEmail',
        method: 'POST'
    }
}

export const WEBSITE_NAME = 'Soracom Wizard'


// Server response codes
//Sucess
export const OK_200 = 200;
export const CREATED_201 = 201;
export const NO_CONTENT_204 = 204;


//User error
export const BAD_REQUEST_400 = 400;
export const UNAUTORIZED_401 = 401;
export const FORBIDDEN_403 = 403;

//Server error
export const INERNAL_SERVER_ERROR = 500;

export const USER_LEVEL = [
    {
        name: 'No access',
        access: ['']
    },
    {
        name: 'Basic User',
        access: ['Basic Access']
    }
]

