import { BACKEND } from '../../constants';
import axios from '../../axios';

export const getOnRegistrationProcess = (page = 1, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.get(BACKEND.ON_REGISTRATION_PROCESS.uri.replace(':?1', page));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const getRegistrationDetails = (id, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.get(BACKEND.REGISTRATION_DETAILS.uri.replace(':?1', id));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const startRegistration = (googleGroup, customerEmail, customerName, spreadsheetLink, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.START_REGISTRATION.uri, {
            googleGroup, customerEmail, customerName, spreadsheetLink
        });
        callback(null, response, 2);
    } catch (e) {
        callback(e)
    }
}

export const finishStep1 = (id, googleGroup, customerEmail, customerName, spreadsheetLink, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.REGISTRATION_STEP.uri.replace(':?1', id).replace(':?2', 'step1'), {
            id, googleGroup, customerEmail, customerName, spreadsheetLink
        });
        callback(null, response, 2);
    } catch (e) {
        callback(e)
    }
}

export const finishStep2 = (id, formFilledConfirmation, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.REGISTRATION_STEP.uri.replace(':?1', id).replace(':?2', 'step2'), {
            id, formFilledConfirmation
        });
        callback(null, response, 3);
    } catch (e) {
        callback(e)
    }
}

export const finishStep3 = (id, operatorId, userName, password, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.post(BACKEND.REGISTRATION_STEP.uri.replace(':?1', id).replace(':?2', 'step3'), {
            id, operatorId, userName, password
        });
        callback(null, response, 5);
    } catch (e) {
        callback(e)
    }
}

export const getCompletedRegistration = (page = 1, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.get(BACKEND.COMPLETED_REGISTRATION.uri.replace(':?1', page));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const deleteRegistration = (id, callback) => async (dispatch) => {
    let response
    try {
        response = await axios.delete(BACKEND.REGISTRATION_DETAILS.uri.replace(':?1', id));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}
