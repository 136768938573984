import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { capitalizeFirstLetter } from '../../helpers/helper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const getData = (currentSubscriberStatus, selectedLegends) => {
  const datasets = [{
    label: 'Registered',
    backgroundColor: 'rgb(53, 162, 235)',
    data: []
  }, {
    label: 'Activated',
    backgroundColor: 'rgb(75, 192, 192)',
    data: []
  }];
  const operatorIds = [];

  for (const subscriberStatus of currentSubscriberStatus) {
    if (selectedLegends[subscriberStatus.operatorId]) {
      //add it to the operatorIds
      operatorIds.push(subscriberStatus.operatorId);
      //add it to the dataset
      datasets[0].data.push(subscriberStatus.registered); //Registered
      datasets[1].data.push(subscriberStatus.activated); //Activated
      // datasets[2].data.push(subscriberStatus.expired); //Expired
    }
  }

  return {
    labels: operatorIds.map(operatrorId => selectedLegends[operatrorId] ? capitalizeFirstLetter(selectedLegends[operatrorId].name.replace('soracom_', '').replace('@thinxtra.com', '')) : operatrorId),
    datasets
  }
}

const ActiveRegisteredChart = props => {

  const { currentSubscriberStatus, legend } = props;


  //We get two things from the props daily billings and legend

  let chartToDisplay = '';
  if (currentSubscriberStatus.length && Object.keys(legend).length) {
    //using the legend get the selected legends
    const selectedLegends = Object.keys(legend).reduce((newDict, operatorId) => !legend[operatorId].disabled ? ({ ...newDict, [operatorId]: legend[operatorId] }) : newDict, {});
    //with the selected legends get the data to display
    const data = getData(currentSubscriberStatus, selectedLegends);

    const options = {
      plugins: {
        title: {
          display: true,
          text: 'Subscriber Status',
        },
        datalabels: {
          display: Object.keys(selectedLegends).length <= 10,
          color: "black",
          formatter: Math.round,
          anchor: "end",
          offset: -20,
          align: "start"
        }
      },
      responsive: true,
      scales: {
        x: {
          stacked: false,
          ticks: {
            autoSkip: false
          }
        },
        y: {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        }
      },
    };

    chartToDisplay = (<Bar options={options} plugins={[ChartDataLabels]} data={data} />)
  }

  return (
    <>
      {chartToDisplay}
    </>
  )
}

export default ActiveRegisteredChart;