import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, fade } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import SearchIcon from '@material-ui/icons/Search';

let headCells;

function descendingComparator(a, b, orderBy) {
  const orderByPosition = headCells.indexOf(headCells.find((obj, index) => obj.id === orderBy));

  if (headCells[orderByPosition].type === 'Float') {
    if (parseInt(b[orderByPosition], 10) < parseInt(a[orderByPosition], 10)) {
      return -1;
    }
    if (parseInt(b[orderByPosition], 10) > parseInt(a[orderByPosition], 10)) {
      return 1;
    }
    return 0;
  }

  if (b[orderByPosition] < a[orderByPosition]) {
    return -1;
  }
  if (b[orderByPosition] > a[orderByPosition]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function searchData(array, searchBy, searchTerm) {
  if (!searchTerm)
    return array;
  if (searchBy === 'search-everywhere' || searchBy === "") {
    //search everywhere
    return array.filter(el => {
      return el.join(';').search(searchTerm) !== -1
    })
  } else {
    const searchByPosition = headCells.indexOf(headCells.find((obj, index) => obj.id === searchBy))
    return array.filter(el => el[searchByPosition].search(searchTerm) !== -1)
  }
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding='default'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const handleSearchByChange = (event) => {
    props.setSearchBy(event.target.value);
  };

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div" noWrap>{props.tableName}</Typography>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={props.searchTerm}
          onChange={(event) => props.setSearchTerm(event.target.value)}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      <FormControl className={classes.formControl}>
        <Select
          value={props.searchBy}
          onChange={handleSearchByChange}
        >
          <MenuItem key='search-everywhere' value='search-everywhere'>Everywhere</MenuItem>
          {
            headCells.map((headCell, index) => {
              if (headCell.isSearchable)
                return <MenuItem key={`search-by-${index}-${headCell.id}`} value={headCell.id}>{headCell.label}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const EnhancedTable = (props) => {
  headCells = props.headCells;

  const classes = useStyles();
  const [order, setOrder] = React.useState(props.order ? props.order : 'asc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy ? props.orderBy : headCells[0].id);
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [searchBy, setSearchBy] = useState('search-everywhere');
  const [searchTerm, setSearchTerm] = useState('');

  const [dateCells] = useState(props.headCells.reduce((allDates, headCell, key) => {
    if (headCell.type === 'Date') {
      return {
        ...allDates,
        [key]: headCell.id
      }
    }
    return allDates;
  }, {}));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let rows = searchData(props.rows, searchBy, searchTerm)

  let toolbar;

  if (props.noSearch) {
    toolbar = (<Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div" noWrap>{props.name}</Typography>
    </Toolbar>)
  } else {
    toolbar = (<EnhancedTableToolbar
      tableName={props.name}
      searchBy={searchBy} setSearchBy={setSearchBy}
      searchTerm={searchTerm} setSearchTerm={setSearchTerm}
    />)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {toolbar}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      style={{
                        cursor: props.onClick ? 'pointer' : 'inherit'
                      }}
                      onClick={(event) => { if (props.onClick) props.onClick(event, row) }}
                      tabIndex={-1}
                      key={index}
                    >
                      {row.map((el, key) => <TableCell align="left" key={`${index}-${key}`}>{dateCells[key] ? `${el.toLocaleDateString('en-GB')} ${String(el.getHours()).padStart(2, '0')}:${String(el.getMinutes()).padStart(2, '0')}` : el}</TableCell>)}
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 200, 300, 400, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default EnhancedTable;
