import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import { Done } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        backgroundColor: '#000',
    },
    nextButton: {
        marginLeft: 'auto'
    },
    actionCards: {
        marginTop: 'auto'
    },
    disablePadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    inline: {
        display: 'inline',
    },
}));


const Step5 = props => {
    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false);
    const [processState, setProcessState] = useState(0);

    let continuousUpdate;
    useEffect(() => {
        if (!continuousUpdate)
            continuousUpdate = props.startContinuousUpdate();

        if (props.workingCustomer.currentPosition == 6)
            if (continuousUpdate)
                clearInterval(continuousUpdate);

        return () => {
            if (continuousUpdate)
                clearInterval(continuousUpdate)
        }
    }, [props.workingCustomer])

    const status = props.workingCustomer.status || {};

    return (

        <Card className={classes.root} disableSpacing>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>4</Avatar>
                }
                title="Step 4"
                subheader="Finializing Registration"
            />

            <CardContent>
                <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{status.loggedIn ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Logging in"
                            secondary={
                                <React.Fragment>
                                    {status.loggedIn || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{status.usersAndRoles ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Retrieving user information"
                            secondary={
                                <React.Fragment>
                                    {status.usersAndRoles || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{(status.updatedUserRole || status.createdUserRole) ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Create/Update user role"
                            secondary={
                                <React.Fragment>
                                    {status.createdUserRole || ''}
                                    {status.updatedUserRole || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{(status.customerUser && status.customerUserRole && status.customerUserPassword) ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Creating customer user"
                            secondary={
                                <React.Fragment>
                                    {status.customerUserFound || ''}
                                    {status.customerUserFound ? <br /> : ''}
                                    {status.customerUser || ''}
                                    <br />
                                    {status.customerUserRole || ''}
                                    <br />
                                    {status.customerUserPassword || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{(status.backupUser && status.backupUserAuthKeys && status.backupUserPermission) ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Creating backup user"
                            secondary={
                                <React.Fragment>
                                    {status.backupUserFound || ''}
                                    {status.backupUserFound ? <br /> : ''}
                                    {status.backupUser || ''}
                                    <br />
                                    {status.backupUserAuthKeys || ''}
                                    <br />
                                    {status.backupUserPermission || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{status.mainUserAuthKeys ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Main user"
                            secondary={
                                <React.Fragment>
                                    {status.mainUserAuthKeysFound || ''}
                                    {status.mainUserAuthKeysFound ? <br /> : ''}
                                    {status.mainUserAuthKeys || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{status.savedUser ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Saving"
                            secondary={
                                <React.Fragment>
                                    {status.savedUser || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>{status.emailSent ? <DoneIcon /> : <CircularProgress />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Send Email."
                            secondary={
                                <React.Fragment>
                                    {status.emailSent || ''}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>


            </CardContent>

            <CardActions disableSpacing classes={{ root: classes.actionCards }}>
                {props.prev && processState !== 9 ? <Button variant="outlined" color="success" startIcon={<ChevronLeft />} onClick={props.prev}>Previous</Button> : ''}
                {processState == 9 ? <Button variant="outlined" color="success" endIcon={<CloseIcon />} className={classes.nextButton} onClick={props.next}>Finish</Button> : ''}
            </CardActions>

        </Card >
    )
}

export default Step5;
