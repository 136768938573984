import { LIST_SORACOM_SUBSCRIBERS, CLEAR_SORACOM_SUBSCRIBERS } from '../actionTypes/soracomSubscribers';
import { BACKEND } from '../../constants';
import axios from '../../axios';

export const getSoracomSubscribers = (page, rowsPerPage, searchOn, search) => async (dispatch, getState) => {
    let response
    try {

        response = await axios.get(BACKEND.LIST_SORACOM_SUBSCRIBERS.uri, { params: { page, rowsPerPage, searchOn, search } });

        dispatch({
            type: LIST_SORACOM_SUBSCRIBERS,
            payload: [
                response.data[0].map(soracomSubscriber => [soracomSubscriber.imsi, soracomSubscriber.msisdn, soracomSubscriber.operatorId, soracomSubscriber.simId, soracomSubscriber.moduleType, soracomSubscriber.iccid, soracomSubscriber.serialNumber]),
                response.data[1]
            ]
        })
    } catch (e) {

    }
}

export const getSoracomSubscriberDetails = (imei, callback) => async (dispatch) => {
    let response
    try {

        response = await axios.get(BACKEND.SORACOM_SUBSCRIBER_DETAILS.uri.replace(':?1', imei));
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const getSoracomSubscriberDetailsLive = (imei, callback) => async (dispatch) => {
    let response
    try {

        response = await axios.get(BACKEND.SORACOM_SUBSCRIBER_DETAILS.uri.replace(':?1', imei) + '/live');
        callback(null, response);
    } catch (e) {
        callback(e)
    }
}

export const clearSoracomSubscribers = () => {
    return {
        type: CLEAR_SORACOM_SUBSCRIBERS,
        payload: null
    }
}
