function getRandomString(length) {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}


//Source : https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
function nFormatter(num, digits = 1) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

function findUnits(billItemName) {
  console.log(billItemName)
  //match and return the values
  switch (true) {
    case /basicCharge-plan/.test(billItemName):
      return 'Days';
    case /chargeCoupon/.test(billItemName):
      return '';
    case /dataTrafficCharge-/.test(billItemName):
      return 'MB';
    case /dataTrafficRoundedUpCharge-/.test(billItemName):
      return 'MB';
    case /soracomAirBasicChargeTieredVolumeDiscount-/.test(billItemName):
      return 'Day';
    case /soracomArcVirtualSimInitialCharge/.test(billItemName):
      return 'Virtual Sims';
    case /soracomArcVirtualSimWithoutSimMonthlyCharge/.test(billItemName):
      return 'Month/Virtual Sim';
    case /soracomBeamRequestCharge/.test(billItemName):
      return 'Request';
    case /soracomBeamRequestFreeTier/.test(billItemName):
      return '';
    case /soracomDoorVPNConnectionCharge/.test(billItemName):
      return 'VPN Connection Hour';
    case /soracomHarvestCharge/.test(billItemName):
      return 'Device/Day';
    case /soracomHarvestFreeTier/.test(billItemName):
      return '';
    case /soracomHarvestRequestCharge/.test(billItemName):
      return 'Request';
    case /soracomNapterCharge/.test(billItemName):
      return 'Sim';
    case /soracomNapterFreeTier/.test(billItemName):
      return '';
    case /soracomOrbitCharge/.test(billItemName):
      return 'Device';
    case /soracomOrbitFreeTier/.test(billItemName):
      return '';
    case /soracomOrbitRequestCharge/.test(billItemName):
      return 'Request';
    case /soracomOrbitRequestFreeTier/.test(billItemName):
      return '';
    case /soracomPeekChargeForSIM/.test(billItemName):
      return 'Time';
    case /soracomPeekForSIMFreeTier/.test(billItemName):
      return '';
    case /soracomSmsDeliveryCharge/.test(billItemName):
      return 'Time';
    case /subscriberActivationCharge/.test(billItemName):
      return 'Sim';
    case /subscriberContractRenewalCharge/.test(billItemName):
      return 'Sim/Year';
    case /subscriptionUsageCharge/.test(billItemName):
      return 'Sim/Month';
    case /virtualPrivateGatewayTypeFCharge/.test(billItemName):
      return 'Hours';
    default:
      return ''
  }
}

module.exports = {
  getRandomString,
  nFormatter,
  capitalizeFirstLetter,
  findUnits
}