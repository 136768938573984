import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles'

import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux'
import * as actions from '../../store/actions/localUsers';

import { USER_LEVEL } from '../../constants';

const AddUser = props => {
    const classes = styles();
    const [isProcessing, setProcessing] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userLevel, setUserLevel] = useState(0);

    const [firstNameError, setFirstNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleFirstName = (event) => {
        setFirstNameError(!event.target.value.length)
        setFirstName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmailError(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(event.target.value))
        setEmail(event.target.value)
    }

    const handlePassword = (event) => {
        setPasswordError(!event.target.value.length)
        setPassword(event.target.value)
    }

    const handleSubmit = (event) => {
        //send the data
        event.preventDefault();
        if (firstNameError || emailError || passwordError || firstName.length == 0 || password.length == 0)
            return props.showNotification({
                color: 'warning',
                message: "First name, email and passwords are required.",
                time: 5000
            })
        setProcessing(true);
        props.addLocalUser(firstName, lastName, email, password, userLevel, (err, response) => {
            setProcessing(false);
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.messsage,
                    time: 5000
                })

            setFirstName('');
            setLastName('');
            setEmail('');
            setPassword('');
            setUserLevel(0);

            setFirstNameError(false);
            setEmailError(false);
            setPasswordError(false);

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });
            props.getLocalUsers()
            props.setModalOpen(false);
        })

    }

    return (
        <GridItem xs={12}>
            <Card >
                <CardBody>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="First name"
                                id="firstName"
                                inputProps={{
                                    value: firstName,
                                    onChange: handleFirstName,
                                    error: firstNameError
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Last name"
                                id="lastName"
                                inputProps={{
                                    value: lastName,
                                    onChange: (event) => setLastName(event.target.value),
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Email"
                                id="email"
                                inputProps={{
                                    value: email,
                                    onChange: handleEmail,
                                    error: emailError
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Password"
                                id="password"
                                inputProps={{
                                    type: "password",
                                    value: password,
                                    onChange: handlePassword,
                                    error: passwordError,

                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />

                        </GridItem>

                        <GridItem xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="title-select-input">User Level</InputLabel>
                                <Select
                                    labelId="title-select-input"
                                    id="title-select"
                                    value={userLevel}
                                    onChange={(event) => setUserLevel(event.target.value)}
                                >
                                    {USER_LEVEL.map((userDetails, index) => <MenuItem value={index}>{`${userDetails.name} ( ${userDetails.access.join(', ')})`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </GridItem>

                        <GridItem xs={12} className={classes.logoHolder}>
                            <div className={classes.buttonHolder}>
                                <Button type="submit" color="primary"
                                    disabled={isProcessing}
                                >Add User</Button>
                            </div>
                        </GridItem>
                    </form>
                </CardBody>
            </Card>
        </GridItem>
    )
}

const styles = makeStyles({});

const mapStateToProps = State => {
    return {
        users: State.users
    }
}

export default connect(mapStateToProps, actions)(AddUser)
