import { LIST_LOCAL_USERS } from '../actionTypes/localUsers'

export default (state = [], action) => {
    switch (action.type) {
        case LIST_LOCAL_USERS:
            return action.payload
        default:
            return state

    }
}
