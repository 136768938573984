import React, { useState, useEffect } from 'react'
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import GridItem from "../../components/Grid/GridItem";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux';

import CustomerDetailLogs from './CustomerDetailLogs'

import * as actions from '../../store/actions/registration';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        width: '100%',
        height: '100%'
    },
    noPadding: {
        padding: '0 !important',
        maxHeight: '95vh',
        maxWidth: '95vw',
        overflow: 'scroll !important',
    }

}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Registration completed detailed logs model"
            aria-describedby="Registration completed detailed logs">
            <Fade in={props.modalOpen}>
                <GridItem container spacing={0} xs={10} sm={8} md={6} lg={4} classes={{ root: classes.noPadding }}>
                    <div className={classes.paper}>
                        {props.children}
                    </div>
                </GridItem>
            </Fade>
        </Modal>
    )
}


const CompletedRegistration = (props) => {
    const classes = useStyles();

    const [addUserModalOpen, setAddUserModalOpen] = useState(false);

    const [completedCustomers, setCompletedCustomers] = useState([]);

    const [workingCustomer, setWorkingCustomer] = useState(null);

    const getCompletedCustomers = (page = 1) => {
        props.getCompletedRegistration(page, (err, response) => {
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 5000
                });
            setCompletedCustomers(response.data);
        })
    }

    const getWorkingCustomerDetails = id => {
        props.getRegistrationDetails(id, (err, response) => {
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 5000
                });

            setWorkingCustomer(response.data);
        })
    }


    const deleteRegistration = () => {
        if (workingCustomer)
            props.deleteRegistration(workingCustomer._id, (err, response) => {
                if (err)
                    return props.showNotification({
                        color: 'warning',
                        message: err.response.data.message,
                        time: 5000
                    });

                props.showNotification({
                    color: 'success',
                    message: response.data.message,
                    time: 5000
                });
                setAddUserModalOpen(false);
                getCompletedCustomers();
            });
    }

    const onTableItemClicked = (event, row) => {
        const clickedUser = completedCustomers.find(customer => customer.step1.googleGroup == row[2]);
        setWorkingCustomer(clickedUser);
        setAddUserModalOpen(true);
        getWorkingCustomerDetails(clickedUser._id);
    }

    useEffect(() => {
        getCompletedCustomers();
    }, [])




    const headCells = [
        { id: 'customerName', label: 'Customer Name', isSearchable: true },
        { id: 'customerEmail', label: 'Customer Email', isSearchable: true },
        { id: 'customerGroup', label: 'Customer Group', isSearchable: true },
        { id: 'operatorId', label: 'OperatorID', isSearchable: true },
        { id: 'completedAt', label: 'Completed At', isSearchable: false, type: 'Date' },
    ];

    return (
        <>
            <SimpleModal modalOpen={addUserModalOpen} setModalOpen={setAddUserModalOpen}>
                <CustomerDetailLogs next={() => setAddUserModalOpen(false)} workingCustomer={workingCustomer} deleteRegistration={deleteRegistration} />
            </SimpleModal>

            <Table rows={completedCustomers.map(customer => [customer.step1.customerName, customer.step1.customerEmail, customer.step1.googleGroup, customer.step3.operatorId, new Date(customer.updatedAt)])} headCells={headCells} onClick={onTableItemClicked} name="Completed Registration" />
        </>
    )
}

export default withBase(connect(null, actions)(CompletedRegistration));
