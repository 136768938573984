import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase'

import { makeStyles } from '@material-ui/styles'

import Button from "../../components/CustomButtons/Button";

import * as actions from '../../store/actions/importExport';

import { connect } from 'react-redux'

const ImportFromKDBX = props => {

    const classes = styles();

    const [isProcessing, setProcessing] = useState(false);

    useEffect(() => {
        if (Object.keys(props.keepass).length == 0)
            props.history.push('/imp-exp/import');

        return () => {
            props.clearKeepassData()
        }
    }, [props.keepass])

    const headCells = [
        { id: 'uuid', label: 'UUID', isSearchable: true },
        { id: 'title', label: 'Title', isSearchable: true },
        { id: 'username', label: 'UserName', isSearchable: true },
        { id: 'notes', label: 'Notes', isSearchable: true },
    ];

    const handleClick = (event, row) => {

    }

    const handleConfirm = () => {
        props.confirmImport(props.keepass, (error, response) => {
            if (error)
                return props.showNotification({
                    color: 'warning',
                    message: error.response.data.message,
                    time: 5000
                })

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            })
        });
    }

    return (
        <>
            {props.keepass.dataToAdd && Object.keys(props.keepass.dataToAdd).length ? <Table rows={props.keepass.dataToAdd.map(keepassEntry => [keepassEntry.uuid, keepassEntry.fields.Title, keepassEntry.fields.UserName, keepassEntry.fields.Notes])} headCells={headCells} noSearch onClick={handleClick} name="Adding Keys" /> : ''}
            {props.keepass.dataToUpdate && Object.keys(props.keepass.dataToUpdate).length ? <Table rows={props.keepass.dataToUpdate.map(keepassEntry => [keepassEntry.uuid, keepassEntry.fields.Title, keepassEntry.fields.UserName, keepassEntry.fields.Notes])} headCells={headCells} noSearch onClick={handleClick} name="Updating Keys" /> : ''}
            {props.keepass.dataExist && Object.keys(props.keepass.dataExist).length ? <Table rows={props.keepass.dataExist.map(keepassEntry => [keepassEntry.uuid, keepassEntry.fields.Title, keepassEntry.fields.UserName, keepassEntry.fields.Notes])} headCells={headCells} noSearch onClick={handleClick} name="Existing Keys" /> : ''}
            {props.keepass.dataToIgnore && Object.keys(props.keepass.dataToIgnore).length ? <Table rows={props.keepass.dataToIgnore.map(keepassEntry => [keepassEntry.uuid, keepassEntry.fields.Title, keepassEntry.fields.UserName, keepassEntry.fields.Notes])} headCells={headCells} noSearch onClick={handleClick} name="Ignoring Keys" /> : ''}
            <Button color="primary" onClick={handleConfirm}>Confirm import</Button>
        </>
    );
}

const styles = makeStyles({
});

const mapStateToProps = (state) => {
    return {
        keepass: state.importExport.import
    }
}

export default withBase(connect(mapStateToProps, actions)(ImportFromKDBX))