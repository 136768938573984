import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import moment from 'moment-timezone';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { capitalizeFirstLetter, nFormatter } from '../../helpers/helper';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const getDataMonthlyBilling = (monthlyBillings, selectedLegends) => {
  const tmpObj = {};

  for (const month of Object.keys(monthlyBillings)) {
    for (const operatorId of Object.keys(monthlyBillings[month])) {
      if (selectedLegends[operatorId]) {
        if (!tmpObj[operatorId]) {
          tmpObj[operatorId] = []
        }
      } else {
        continue;
      }
      tmpObj[operatorId].push({
        x: moment(month, 'YYYYMM').format('YYYY-MM'),
        y: monthlyBillings[month][operatorId].total.toFixed(4)
      })
    }
  }

  return {
    datasets: Object.keys(tmpObj).map((operatrorId) => {
      return {
        label: selectedLegends[operatrorId] ? capitalizeFirstLetter(selectedLegends[operatrorId].name.replace('soracom_', '').replace('@thinxtra.com', '')) : operatrorId,
        data: tmpObj[operatrorId],
        borderColor: selectedLegends[operatrorId].color,
        backgroundColor: selectedLegends[operatrorId].color
      }
    })
  }
}

const MonthlyBillingsChart = props => {


  //We get two things from the props daily billings and legend

  const { monthlyBillings, legend } = props;
  let chartToDisplay = '';
  if (Object.keys(monthlyBillings).length && Object.keys(legend).length) {
    //using the legend get the selected legends
    const selectedLegends = Object.keys(legend).reduce((newDict, operatorId) => !legend[operatorId].disabled ? ({ ...newDict, [operatorId]: legend[operatorId] }) : newDict, {});
    //with the selected legends get the data to display
    const data = getDataMonthlyBilling(monthlyBillings, selectedLegends);
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        datalabels: {
          display: Object.keys(selectedLegends).length < 5,
          color: "black",
          formatter: (obj) => nFormatter(obj.y),
          anchor: "end",
          offset: -20,
          align: "start"
        },
        title: {
          display: true,
          text: 'Monthy Billings For Customer(USD)',
        },
      },
    };
    chartToDisplay = (<Line options={options} data={data} plugins={[ChartDataLabels]} />)
  }

  return (
    <>
      {chartToDisplay}
    </>
  )
}

export default MonthlyBillingsChart;