import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";

import SoracomUserDetails from "./SoracomUserDetails";

import { BACKEND } from '../../constants'

import { connect } from 'react-redux';

import * as actions from '../../store/actions/soracomUsers'


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '500px'
    },
}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Soracom user details"
            aria-describedby="Soracom user details">
            <Fade in={props.modalOpen}>
                <div className={classes.paper} style={{
                    width: '90vw',
                    minHeight: '50vh',
                    maxHeight: '90vh',
                    overflowY: 'scroll'
                }}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
}

const addSoracomuser = async (name, operatorId, userName, password, token) => {
    let response
    try {
        response = await axios.post(`${BACKEND.BASE}${BACKEND.ADD_SORACOM_USER.uri}`, { name, operatorId, userName, password }, {
            headers: { 'content-type': 'application/json', 'Authorization': token },
        });
        return response.data.message;
    } catch (err) {
        throw err;
    }
}

const updateSoracomUsersCredentials = async (operatorId, userName, password, token) => {
    let response
    try {
        response = await axios.patch(`${BACKEND.BASE}${BACKEND.ADD_SORACOM_USER_CREDENTIALS.uri}`, { operatorId, userName, password }, {
            headers: { 'content-type': 'application/json', 'Authorization': token },
        });
        return response.data.message;
    } catch (err) {
        throw err;
    }
}


const AddSoracomuserFabButton = props => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = React.useState(false);

    const [name, setName] = useState('');
    const [operatorId, setOperatorId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [isProcessing, setProcessing] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [operatorIdError, setOperatorIdError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleNameEvent = (event) => {
        setName(event.target.value)
        setNameError(event.target.value.length === 0)
    }

    const handleOperatorIdEvent = (event) => {
        setOperatorId(event.target.value)
        setOperatorIdError(event.target.value.length === 0)
    }

    const handleUserNameEvent = (event) => {
        setUserName(event.target.value)
        setUserNameError(event.target.value.length === 0)
    }

    const handlePasswordEvent = (event) => {
        setPassword(event.target.value)
        setPasswordError(event.target.value.length === 0)
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (name.length && operatorId.length && userName.length && password.length) {
            setProcessing(true)
            try {
                const message = await addSoracomuser(name, operatorId, userName, password, props.token);
                setProcessing(false);
                setName('')
                setOperatorId('')
                setUserName('')
                setPassword('')
                setNameError(false)
                setOperatorIdError(false)
                setUserNameError(false)
                setPasswordError(false);
                props.getSoracomUsers();
                setModalOpen(false);
                props.showNotification({
                    color: 'success',
                    message,
                    time: 3000
                })
            } catch (err) {
                setProcessing(false)
                props.showNotification({
                    color: 'danger',
                    message: err.response && err.response.data ? err.response.data.message : 'Please refresh!',
                    time: 3000
                })
            }
        } else {
            props.showNotification({
                color: 'warning',
                message: "The fields cannot be empty",
                time: 3000
            })
        }


    }

    return (
        // display the add user buton towards the right of the screen
        <>
            <Fab size="small" className={classes.fab} color="primary" aria-label="add" onClick={() => setModalOpen(true)}>
                <AddIcon />
            </Fab>
            <Modal
                className={classes.modal}
                open={modalOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                onClose={() => setModalOpen(false)}
                aria-labelledby="Add soracom user model"
                aria-describedby="Add soracom user">
                <Fade in={modalOpen}>
                    <div className={classes.paper}>
                        <form onSubmit={handleFormSubmit}>
                            <Typography >Add soracom user</Typography>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Name *"
                                    inputProps={{
                                        value: name,
                                        onChange: handleNameEvent,
                                        error: nameError
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root
                                    }}

                                />
                            </GridItem>

                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Operator Id *"
                                    inputProps={{
                                        value: operatorId,
                                        onChange: handleOperatorIdEvent,
                                        error: operatorIdError
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root
                                    }}

                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="User Name *"
                                    inputProps={{
                                        value: userName,
                                        onChange: handleUserNameEvent,
                                        error: userNameError
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root
                                    }}

                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText="Password *"
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.root,
                                    }}
                                    inputProps={{
                                        type: "password",
                                        value: password,
                                        onChange: handlePasswordEvent,
                                        error: passwordError
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} className={classes.logoHolder}>
                                <div className={classes.buttonHolder}>
                                    <Button type="submit" color="primary"
                                        disabled={isProcessing}
                                    >Login</Button>
                                </div>
                            </GridItem>

                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    )

}

const SoracomUser = (props) => {

    useEffect(() => {
        props.getSoracomUsers();

        return () => {
            props.clearSoracomUser();
        }
    }, [])

    const [soracomUserDetailsModelOpen, setSoracomUserDetailsModelOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);


    const headCells = [
        { id: 'Name', label: 'Name', isSearchable: true },
        { id: 'Operator id', label: 'Operator id', isSearchable: true },
        { id: 'Username', label: 'Username', isSearchable: true },
        { id: 'registered', label: 'Registered(Sims)' },
        { id: 'activated', label: 'Activated(Sims)' },
        { id: 'expired', label: 'Expired(Sims)' },
    ];

    const handleClick = (event, row) => {
        setSelectedUser(row);
        setSoracomUserDetailsModelOpen(true);
    }

    return (
        <>
            <AddSoracomuserFabButton showNotification={props.showNotification} token={props.auth.token} getSoracomUsers={props.getSoracomUsers} />
            <SimpleModal modalOpen={soracomUserDetailsModelOpen} setModalOpen={setSoracomUserDetailsModelOpen}>
                <SoracomUserDetails selectedUser={selectedUser} setModalOpen={setSoracomUserDetailsModelOpen} showNotification={props.showNotification} updateSoracomUsersCredentials={updateSoracomUsersCredentials} token={props.auth.token} />
            </SimpleModal>
            <Table rows={props.soracomUsers} headCells={headCells} onClick={handleClick} name="Soracom Users" />
        </>
    )
}
const mapStateToProps = State => {
    return {
        auth: State.auth,
        soracomUsers: State.soracomUsers
    }
}
export default withBase(connect(mapStateToProps, actions)(SoracomUser));
