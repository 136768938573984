import { IMPORT_KEEPASS_DATA, ERROR_IMPORTING_DATA, CLEAR_KEEPASS_DATA, EXPORT_KEEPASS_DATA } from '../actionTypes/importExport';
import { BACKEND } from '../../constants';
import axios from '../../axios';
import { saveAs } from 'file-saver'

export const postDatabaseFile = (file, groupName, databasePassword, callback) => async (dispatch, getState) => {
    let formData = new FormData();
    formData.set("file", file);
    formData.set("groupName", groupName);
    formData.set("databasePassword", databasePassword);

    axios.post(BACKEND.IMPORT_KEEPASS_DATABASE.uri, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(response => dispatch({
        type: IMPORT_KEEPASS_DATA,
        payload: response.data
    })).catch(err => callback(err));
}

export const confirmImport = (dataToSend, callback) => async (dispatch, getState) => {
    axios.put(BACKEND.IMPORT_KEEPASS_DATABASE.uri, dataToSend)
        .then(response => {
            callback(null, response);
            setTimeout(() => dispatch({
                type: CLEAR_KEEPASS_DATA
            }), 3000);
        }).catch(err => callback(err))
}

export const exportKeepass = (callback) => async (dispatch) => {
    axios.get(BACKEND.EXPORT_KEEPASS_DATABASE.uri)
        .then(response => {
            dispatch({
                type: EXPORT_KEEPASS_DATA,
                payload: response.data
            })
        }).catch(err => callback(err))
}

export const confirmExport = (selectedUUID, dbName, groupName, password, callback) => async (dispatch, getState) => {

    axios({
        method: 'post',
        url: BACKEND.EXPORT_KEEPASS_DATABASE.uri,
        data: { selectedUUID, selectedUUID, dbName, groupName, password },
        responseType: 'blob'
    })
        .then(response => {
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'fileName.kdbx');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }).catch(err => {
            callback(err)
        })
}

export const clearKeepassData = () => {
    return {
        type: CLEAR_KEEPASS_DATA,
        payload: null
    }
}