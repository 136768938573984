import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";

import PublishIcon from '@material-ui/icons/Publish';

import { makeStyles } from '@material-ui/styles'

import * as actions from '../../store/actions/keepass';
import { connect } from 'react-redux'

import SimpleTabs from './SimpleTabs'

import KeepassDetails from './KeepassDetails';


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid    #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: '50vw',
        maxWidth: '90vw',
        minHeight: '50vh',
        maxHeight: '90vh',
        overflowY: 'scroll'
    },

}));

const SimpleModal = props => {
    const classes = useStyles();

    return (
        <Modal
            className={classes.modal}
            open={props.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="Add soracom user model"
            aria-describedby="Add soracom user">
            <Fade in={props.modalOpen}>
                <div className={classes.paper}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
}


const KeepassList = props => {
    const [addKeepassModalOpen, setAddKeepassModalOpen] = React.useState(false);
    const [detailKeepassModalOpen, setDetailKeepassModalOpen] = React.useState(false);
    const [openedKeepass, setOpenedKeepass] = React.useState(null);
    const classes = useStyles();

    useEffect(() => {
        props.getKeepassDataWithUsers((error) => {
            if (error)
                return props.showNotification({
                    color: 'warning',
                    message: error.response.data.message,
                    time: 5000
                })
        });

        return () => {
            props.clearKeepassDataWithUsers();
        }
    }, [])

    const headCells = [
        { id: 'uuid', label: 'UUID', isSearchable: false },
        { id: 'title', label: 'Title', isSearchable: true },
        { id: 'username', label: 'UserName', isSearchable: true },
        { id: 'notes', label: 'Notes', isSearchable: true },
        { id: 'created_at', label: 'Added On', isSearchable: false, type: 'Date' },
    ];


    const handleClick = (event, row) => {
        //display the user details
        //may be use model fot this instead of new page
        setOpenedKeepass(row[0]);
        setDetailKeepassModalOpen(true);
    }

    return (
        <>
            <Table rows={props.keepass.map(keepassEntry => [keepassEntry.uuid, keepassEntry.fields.Title, keepassEntry.fields.UserName, keepassEntry.fields.Notes, new Date(keepassEntry.createdAt)])}
                headCells={headCells}
                onClick={handleClick}
                name="Keepass" />

            <SimpleModal modalOpen={addKeepassModalOpen} setModalOpen={setAddKeepassModalOpen}>
                <SimpleTabs showNotification={props.showNotification} setAddKeepassModalOpen={setAddKeepassModalOpen} />
            </SimpleModal>
            <SimpleModal modalOpen={detailKeepassModalOpen} setModalOpen={setDetailKeepassModalOpen}>
                <KeepassDetails openedKeepass={openedKeepass} showNotification={props.showNotification} setDetailKeepassModalOpen={setDetailKeepassModalOpen} />
            </SimpleModal>
            <Fab size="small" className={classes.fab} color="primary" aria-label="add" onClick={() => setAddKeepassModalOpen(true)}>
                <AddIcon />
            </Fab>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        keepass: state.keepass.keepass,
        users: state.keepass.users
    }
}

export default withBase(connect(mapStateToProps, actions)(KeepassList))