import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles'

import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux'
import * as actions from '../../store/actions/localUsers';

import { USER_LEVEL } from '../../constants';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@material-ui/icons/Save';


const AddUser = props => {
    const classes = styles();

    const [editMode, setEditMode] = useState(false);

    const [isProcessing, setProcessing] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userLevel, setUserLevel] = useState(0);

    const [firstNameError, setFirstNameError] = useState(false);

    useEffect(() => {
        setFirstName(props.selectedUser.first_name);
        setLastName(props.selectedUser.last_name);
        setEmail(props.selectedUser.email);
        setUserLevel(props.selectedUser.userLevel);

        return () => {
            setFirstName('');
            setLastName('');
            setUserLevel(0);

            setFirstNameError(false);
        }
    }, [props.selectedUser])

    const handleFirstName = (event) => {
        setFirstNameError(!event.target.value.length)
        setFirstName(event.target.value)
    }

    const handleSubmit = (event) => {
        //send the data
        if (event)
            event.preventDefault();

        if (firstNameError || firstName.length == 0)
            return props.showNotification({
                color: 'warning',
                message: "First name and email are required.",
                time: 5000
            })
        setProcessing(true);
        props.updateLocalUser(firstName, lastName, email, userLevel, (err, response) => {
            setProcessing(false);
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.messsage,
                    time: 5000
                })

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });
            props.getLocalUsers();
            props.closeModel(false);
        })

    }

    const handleDelete = () => {
        props.deleteteLocalUser(email, (err, response) => {
            if (err) return props.showNotification({
                color: 'warning',
                message: err.response.data.message,
                time: 10000
            });

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });

            props.getLocalUsers()
            props.closeModel();
        });
    };

    const buttonsDisplay = editMode ? (<>
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSubmit} disabled={isProcessing}>Update</Button>
        <Button variant="contained" color="danger" startIcon={<DeleteOutlineIcon />} onClick={handleDelete} disabled={isProcessing}>Delete</Button>
    </>) : (<>
        <Button variant="contained" color="default" startIcon={<EditIcon />} disabled={isProcessing} onClick={() => setEditMode(true)}>Edit</Button>
        <Button variant="contained" color="primary" startIcon={<CloseIcon />} onClick={props.closeModel} >Close</Button>
    </>)

    return (
        <GridItem xs={12}>
            <Card >
                <CardBody>

                    <GridItem xs={12} className={classes.buttonHolder}>
                        <div className={classes.buttonHolder}>
                            {buttonsDisplay}
                        </div>
                    </GridItem>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="First name"
                                id="firstName"
                                inputProps={{
                                    value: firstName,
                                    onChange: handleFirstName,
                                    error: firstNameError,
                                    disabled: !editMode
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Last name"
                                id="lastName"
                                inputProps={{
                                    value: lastName,
                                    onChange: (event) => setLastName(event.target.value),
                                    disabled: !editMode
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Email"
                                id="email"
                                inputProps={{
                                    value: email,
                                    disabled: true,
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="title-select-input">User Level</InputLabel>
                                <Select
                                    labelId="title-select-input"
                                    id="title-select"
                                    value={userLevel}
                                    disabled={!editMode}
                                    onChange={(event) => setUserLevel(event.target.value)}
                                >
                                    {USER_LEVEL.map((userDetails, index) => <MenuItem value={index}>{`${userDetails.name} ( ${userDetails.access.join(', ')})`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </form>
                </CardBody>
            </Card>
        </GridItem>
    )
}

const styles = makeStyles({
    buttonHolder: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

const mapStateToProps = State => {
    return {
        users: State.users
    }
}

export default connect(mapStateToProps, actions)(AddUser)
