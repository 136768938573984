import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles'

import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardBody from "../../components/Card/CardBody";

import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { connect } from 'react-redux'
import * as actions from '../../store/actions/localUsers';

import { USER_LEVEL } from '../../constants';

const AddUser = props => {
    const classes = styles();
    const [isProcessing, setProcessing] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const handlePassword = (event) => {
        setPasswordError(!event.target.value.length)
        setPassword(event.target.value)
    }

    const handleConfirmPassword = (event) => {
        setConfirmPasswordError(!event.target.value.length)
        setConfirmPassword(event.target.value)
    }

    useEffect(() => {
        setEmail(props.selectedUser.email);
        return () => {
            setEmail('');
            setPassword('');
            setConfirmPassword('');

            setPasswordError(false);
            setConfirmPasswordError(false);
        }
    }, [props.selectedUser])

    const handleSubmit = (event) => {
        //send the data
        event.preventDefault();
        if (passwordError || confirmPasswordError || password.length == 0 || confirmPassword.length == 0 || password != confirmPassword)
            return props.showNotification({
                color: 'warning',
                message: "Please make sure passwords match and not empty.",
                time: 5000
            })
        setProcessing(true);
        props.changePassword(email, password, (err, response) => {
            setProcessing(false);
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.messsage,
                    time: 5000
                })

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });

            props.getLocalUsers();
            props.closeModel(false);
        })

    }

    return (
        <GridItem xs={12}>
            <Card >
                <CardBody>
                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>






                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Password"
                                id="password"
                                inputProps={{
                                    type: "password",
                                    value: password,
                                    onChange: handlePassword,
                                    error: passwordError,

                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />

                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Confirm Password"
                                id="confirmPassword"
                                inputProps={{
                                    type: "password",
                                    value: confirmPassword,
                                    onChange: handleConfirmPassword,
                                    error: confirmPasswordError,

                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />

                        </GridItem>

                        <GridItem xs={12} className={classes.logoHolder}>
                            <div className={classes.buttonHolder}>
                                <Button type="submit" color="primary"
                                    disabled={isProcessing}
                                >Update Password</Button>
                            </div>
                        </GridItem>
                    </form>
                </CardBody>
            </Card>
        </GridItem>
    )
}

const styles = makeStyles({});

const mapStateToProps = State => {
    return {
        users: State.users
    }
}

export default connect(mapStateToProps, actions)(AddUser)
