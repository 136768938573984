import { IMPORT_KEEPASS_DATA, CLEAR_KEEPASS_DATA, EXPORT_KEEPASS_DATA } from '../actionTypes/importExport'

export default (state = { import: [], export: [], keepass: [] }, action) => {
    switch (action.type) {
        case IMPORT_KEEPASS_DATA:
            return {
                ...state,
                import: action.payload
            }
        case EXPORT_KEEPASS_DATA:
            return {
                ...state,
                export: action.payload
            }
        case CLEAR_KEEPASS_DATA:
            return { import: [], export: [], keepass: [] }
        default:
            return state
    }
}
