import React, { useState } from 'react';
import Table from '../../components/Table/Table';
import withBase from '../../hoc/withBase'

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CustomInput from "../../components/CustomInput/CustomInput";
import Typography from '@material-ui/core/Typography'
import GridItem from "../../components/Grid/GridItem";

import PublishIcon from '@material-ui/icons/Publish';

import { makeStyles } from '@material-ui/styles'

import * as actions from '../../store/actions/reports';
import { connect } from 'react-redux'

import moment from 'moment-timezone';
import GridContainer from '../../components/Grid/GridContainer';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid    #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: '50vw',
        maxWidth: '90vw',
        minHeight: '50vh',
        maxHeight: '90vh',
        overflowY: 'scroll'
    },

}));

const headCells = [
    { id: 'Name', label: 'Name', isSearchable: true },
    { id: 'Start', label: 'Start', isSearchable: true },
    { id: 'End', label: 'End', isSearchable: true },
    { id: 'Registrations', label: 'Registrations', isSearchable: true },
    { id: 'Activations', label: 'Activations', isSearchable: true },
];

const RegistrationsAndActivationsDelta = props => {
    const [startDate, setStartDate] = React.useState(moment().startOf('week').subtract(1, 'week').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = React.useState(moment().startOf('week').format('YYYY-MM-DD'));
    const [registrationAndActivationsDeltaReport, setRegistrationsAndActivationsDeltaReports] = React.useState([]);
    const classes = useStyles();

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setRegistrationsAndActivationsDeltaReports([]);
        // setProcessing(true);
        props.getRegistrationsAndActivationsDelta(moment(startDate, 'YYYY-MM-DD').format('YYYYMMDD'), moment(endDate, 'YYYY-MM-DD').format('YYYYMMDD'), (err, response) => {
            // setProcessing(false);
            if (err)
                return props.showNotification({
                    color: 'warning',
                    message: err.response.data.messsage,
                    time: 5000
                })

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });
            setRegistrationsAndActivationsDeltaReports(response.data);
        })
    }


    return (
        <>
            <div className={classes.paper}>
                <form onSubmit={handleFormSubmit}>
                    <Typography variant='body1'>Please select the start and end date to get the count of registered and activated devices</Typography>
                    <GridContainer>
                        <GridItem xs={5} item>
                            <CustomInput
                                labelText="Start Date *"
                                inputProps={{
                                    value: startDate,
                                    type: 'date',
                                    onChange: (event) => setStartDate(event.target.value)
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>
                        <GridItem xs={5} item>
                            <CustomInput
                                labelText="End Date *"
                                inputProps={{
                                    value: endDate,
                                    type: 'date',
                                    onChange: (event) => setEndDate(event.target.value)
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>
                        <GridItem xs={2}>
                            <Button type="submit" variant="outlined" color="success" endIcon={<ChevronRight />}>Go</Button>
                        </GridItem>
                    </GridContainer>

                </form>
                <Table rows={registrationAndActivationsDeltaReport.map(keepassEntry => [keepassEntry.name, moment(keepassEntry.start, 'YYYYMMDD').format('DD/MM/YYYY'), moment(keepassEntry.end, 'YYYYMMDD').format('DD/MM/YYYY'), keepassEntry.endRegistered - (keepassEntry.startRegistered || 0), keepassEntry.endActivated - (keepassEntry.startActivated || 0)])}
                    headCells={headCells}
                    onClick={() => { }}
                    name="Activations And Registrations report (Delta)" />
            </div>
        </>
    );
}


export default withBase(connect(null, actions)(RegistrationsAndActivationsDelta))