import { BACKEND } from '../../constants';
import axios from '../../axios';

export const getRegistrationsAndActivationsDelta = (start, end, callback) => async (dispatch) => {
    //should generate a unique uuid and add the data to the database
    //we may need to steal the generate uuid function from kdbxweb
    axios.get(BACKEND.REGISTRATIONS_ACTIVATIONS_REPORT_DELTA.uri, {
        params: { start, end }
    }).then(response => {
        callback(null, response)
    }).catch(err => {
        callback(err)
    })
}

export const getRegistrationsAndActivations = (historyOn, callback) => async (dispatch) => {
    //should generate a unique uuid and add the data to the database
    //we may need to steal the generate uuid function from kdbxweb
    axios.get(BACKEND.REGISTRATIONS_ACTIVATIONS_REPORT.uri, {
        params: { historyOn }
    }).then(response => {
        callback(null, response)
    }).catch(err => {
        callback(err)
    })
}