import { AUTH_USER, AUTH_ERROR, SIGN_OUT, REFRESH_TOKEN, USER_DETAILS, FORCE_LOGOUT } from '../actionTypes/auth'

export default (state = {}, action) => {
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                authenticated: action.payload.authenticated,
                token: action.payload.token,
                user: action.payload.user,
                expiresOn: action.payload.expiresOn
            }

        case AUTH_ERROR:
            return {
                ...state,
                authenticated: false,
                token: null,
                user: null,
                expiresOn: null
            }

        case SIGN_OUT:
            return {
                ...state,
                authenticated: false,
                token: null,
                user: null,
                expiresOn: null
            }

        case REFRESH_TOKEN:
            return {
                ...state,
                authenticated: action.payload.authenticated,
                token: action.payload.token,
                user: action.payload.user,
                expiresOn: action.payload.expiresOn
            }

        case USER_DETAILS:
            return {
                ...state,
                user: action.payload.user,
            }

        default:
            return state
    }
}
