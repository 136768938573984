import { PlaceRounded } from '@material-ui/icons';
import axios from 'axios';
import { UNAUTORIZED_401, BACKEND } from './constants'
import localStorageService from './service/localStorageService';


const forceLogout = () => {
    console.log('The user was logged out');
    localStorageService.setAuthenticated(false);
    localStorageService.setExpiry(null);
    localStorageService.setToken(null);
    window.location.href = '/login';
}

const axiosInstance = axios.create({
    baseURL: BACKEND.BASE,
});

axiosInstance.interceptors.request.use(config => {
    const expiresOn = localStorageService.getExpiry();
    const authorization = localStorageService.getToken();
    const loggedIn = localStorageService.getAuthenticated();

    if (!loggedIn || !authorization || !expiresOn || ((new Date(expiresOn).getTime()) < (new Date().getTime()))) {
        forceLogout();
        return;
    }

    config.headers['Authorization'] = authorization;
    if (config.headers['content-type'] !== "multipart/form-data")
        config.headers['content-type'] = 'application/json';

    return config;
},
    error => {
        Promise.reject(error)

    });

axiosInstance.interceptors.response.use(response => new Promise((resolve, reject) => {
    resolve(response);
}),
    error => new Promise((resolve, reject) => {
        //check if the error was beacuse of authentication then navigate user to login.
        if (error.response && error.response.status === 401) {
            //the user account is not valid, log the user out and send to login page
            forceLogout();
        }

        reject(error)
    }));


export default axiosInstance;
