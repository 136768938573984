import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";

import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        backgroundColor: '#000',
    },
    nextButton: {
        marginLeft: 'auto'
    },
    actionCards: {
        marginTop: 'auto'
    },
    disablePadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    dangerButton: {
        color: 'red',
    }
}));


const Step3 = props => {
    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false);
    const [operatorId, setOperatorId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const disableNext = !operatorId.length || !userName.length || !password.length;

    const handleNext = () => {
        if (!disableNext)
            props.next(3, [operatorId, userName, password]);
    }

    return (

        <Card className={classes.root} disableSpacing>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>3</Avatar>
                }
                title="Step 3"
                subheader="Slack Approval"
                action={
                    <Button variant="outlined" classes={{ root: classes.dangerButton }} aria-label="delete" onClick={props.deleteRegistration}>
                        Delete <DeleteForeverIcon />
                    </Button>
                }
            />

            <CardContent classes={{ root: classes.disablePadding }}>

                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar><DoneAllIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Approve the new customer on slack"
                        secondary={
                            <React.Fragment>
                                Please fill the following details from slack received after approval.
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <GridItem xs={12}>
                    <CustomInput labelText="Operator Id" id='operator_id'
                        inputProps={{
                            value: operatorId,
                            onChange: (event) => setOperatorId(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>

                <GridItem xs={12}>
                    <CustomInput labelText="Thinxtra username" id='thinxtra_username'
                        inputProps={{
                            value: userName,
                            onChange: (event) => setUserName(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>

                <GridItem xs={12} >
                    <CustomInput labelText="Password" id='password'
                        inputProps={{
                            value: password,
                            onChange: (event) => setPassword(event.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>
            </CardContent>

            <CardActions disableSpacing classes={{ root: classes.actionCards }}>
                {props.prev ? <Button variant="outlined" color="success" startIcon={<ChevronLeft />} onClick={props.prev}>Previous</Button> : ''}
                {props.next ? <Button variant="outlined" color="success" endIcon={<ChevronRight />} className={classes.nextButton} onClick={handleNext} disabled={disableNext}>Next</Button> : ''}
            </CardActions>

        </Card >
    )
}

export default Step3;
