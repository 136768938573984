import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


import TextFieldsIcon from '@material-ui/icons/TextFields';
import { ChevronRight, Done } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    avatar: {
        backgroundColor: '#000',
    },
    nextButton: {
        marginLeft: 'auto'
    },
    actionCards: {
        marginTop: 'auto'
    },
    disablePadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    inline: {
        display: 'inline',
    },
    dangerButton: {
        color: 'red',
    }
}));


const Step4 = props => {
    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false);

    const handleNext = () => {
        props.next(4, [...props.credentials]);
    }

    return (

        <Card className={classes.root} disableSpacing>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>4</Avatar>
                }
                title="Step 4"
                subheader="Confirm Details"
                action={
                    <Button variant="outlined" classes={{ root: classes.dangerButton }} aria-label="delete" onClick={props.deleteRegistration}>
                        Delete <DeleteForeverIcon />
                    </Button>
                }
            />

            <CardContent>
                <List className={classes.root}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar><TextFieldsIcon /></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="User Details"
                            secondary={
                                <React.Fragment>
                                    <b>Customer Name: </b> {props.workingCustomer.step1.customerName}
                                    <br />
                                    <b>Customer Email: </b> {props.workingCustomer.step1.customerEmail}
                                    <br />
                                    <b>Google Group: </b> {props.workingCustomer.step1.googleGroup}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar><TextFieldsIcon /></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Credentials"
                            secondary={
                                <React.Fragment>
                                    <b>Operator Id: </b> {props.credentials[0]}
                                    <br />
                                    <b>Username: </b> {props.credentials[1]}
                                    <br />
                                    <b>Password: </b> {props.credentials[2]}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>


            </CardContent>

            <CardActions disableSpacing classes={{ root: classes.actionCards }}>
                {props.prev ? <Button variant="outlined" color="success" startIcon={<ChevronLeft />} onClick={props.prev}>Previous</Button> : ''}
                {props.next && props.credentials.length ? <Button variant="outlined" color="success" endIcon={<ChevronRight />} className={classes.nextButton} onClick={handleNext}>Next</Button> : ''}
            </CardActions>

        </Card >
    )
}

export default Step4;
