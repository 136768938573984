/*eslint-disable*/
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  const [expandStates, setExpandStates] = useState({})
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;

  useEffect(() => {
    setExpandStates(routes.map(route => activeRoute(route.path)));
  }, []);

  const handleClick = (index) => {
    const currentState = [...expandStates];
    currentState[index] = !currentState[index]
    setExpandStates(currentState);
  }

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        const isActiveRoute = activeRoute(prop.path);
        listItemClasses = classNames({
          [" " + classes[color]]: isActiveRoute
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: isActiveRoute
        });
        if (prop.display) {
          return (
            prop.childrens ? (
              <>
                <ListItem key={key} button className={classes.itemLink + listItemClasses} onClick={() => handleClick(key)}>
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                  <ListItemText
                    primary={prop.name}
                    className={classNames(classes.itemText, whiteFontClasses)}
                    disableTypograph={false}
                  />
                  {expandStates[key] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expandStates[key]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {prop.childrens.map((nestedChild, key) => {
                      activePro = " ";
                      listItemClasses;
                      const isActiveRoute = activeRoute(`${prop.path}${nestedChild.path}`);
                      listItemClasses = classNames({
                        [" " + classes[color]]: isActiveRoute
                      });
                      const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: isActiveRoute
                      });
                      return (<NavLink
                        to={`${prop.path}${nestedChild.path}`}
                        className={activePro + classes.item + " " + classes.nested}
                        key={`nested-${key}`}
                      >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                          <nestedChild.icon
                            className={classes.itemIcon}
                          />
                          <ListItemText
                            primary={nestedChild.name}
                            className={classes.itemText}
                            disableTypography={true}
                          />
                        </ListItem>
                      </NavLink>)
                    })
                    }
                  </List>
                </Collapse>
              </>
            ) : (
                <NavLink
                  to={prop.path}
                  className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem button className={classes.itemLink + listItemClasses}>
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses)}
                    />
                    <ListItemText
                      primary={prop.name}
                      className={classNames(classes.itemText, whiteFontClasses)}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              )
          );
        }
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="#"
        className={classes.logoLink}
      >
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
