import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles'


import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PasswordIcon from '@material-ui/icons/VpnKey';
import { getRandomString } from '../../helpers/helper'


import TableWithExternalSearchAndPagination from '../../components/Table/TableWithExternalSearchAndPagination';

import * as actions from '../../store/actions/soracomUsers';
import GridContainer from '../../components/Grid/GridContainer';
import ActiveRegisteredBar from './ActiveRegisteredBar';

const SoracomUserDetails = props => {
    const classes = styles();

    const [soracomUser, setSoracomUser] = useState(null);
    const [soracomSubscribers, setSoracomSubscriber] = useState([[], 0]);
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [updateUserCredentialsMode, setUpdateUserCredentialsMode] = useState(false);
    const [randomString] = useState(() => getRandomString(5));
    const [enteredRandomString, setEnteredRandomString] = useState('');

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [isProcessing, setProcessing] = useState(false);

    const handleUserNameEvent = (event) => {
        setUserName(event.target.value)
        setUserNameError(event.target.value.length === 0)
    }

    const handlePasswordEvent = (event) => {
        setPassword(event.target.value)
        setPasswordError(event.target.value.length === 0)
    }

    const handleDelete = () => {
        setProcessing(true)
        props.deleteSoracomUser(props.selectedUser[1], (err, response) => {
            setProcessing(false)
            if (err) return props.showNotification({
                color: 'warning',
                message: err.response.data.message,
                time: 10000
            });

            props.showNotification({
                color: 'success',
                message: response.data.message,
                time: 5000
            });

            props.getSoracomUsers();
            props.setModalOpen(false);
        });
    }

    console.log('being realoaded')
    useEffect(() => {
        props.getSoracomUserDetails(props.selectedUser[1], (err, response) => {
            if (err) {
                props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 10000
                });
            } else {
                setSoracomUser(response.data);
            }
        });
        handleChangeData(0, 100, 'search-everywhere', '');
    }, []);


    const handleChangeData = (page, rowsPerPage, searchOn, search) => {
        props.getSoracomUserSubscribers(props.selectedUser[1], page, rowsPerPage, searchOn, search, (err, response) => {
            if (err) {
                props.showNotification({
                    color: 'warning',
                    message: err.response.data.message,
                    time: 10000
                });
            } else {
                setSoracomSubscriber([response.data[0].map(soracomSubscriber => [soracomSubscriber.imsi, soracomSubscriber.msisdn, soracomSubscriber.simId, soracomSubscriber.moduleType, soracomSubscriber.iccid, soracomSubscriber.serialNumber]), response.data[1]]);
            }
        });
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (soracomUser.name.length && soracomUser.operatorId.length && userName.length && password.length) {
            setProcessing(true)
            try {
                const message = await props.updateSoracomUsersCredentials(soracomUser.operatorId, userName, password, props.token);
                setProcessing(false);
                setUserName('')
                setPassword('')
                setUserNameError(false)
                setPasswordError(false);
                props.getSoracomUsers();
                props.showNotification({
                    color: 'success',
                    message,
                    time: 3000
                })
            } catch (err) {
                setProcessing(false)
                props.showNotification({
                    color: 'danger',
                    message: err.response && err.response.data ? err.response.data.message : 'Please refresh!',
                    time: 3000
                })
            }

            props.getSoracomUsers();
            props.setModalOpen(false);
        } else {
            props.showNotification({
                color: 'warning',
                message: "The fields cannot be empty",
                time: 3000
            })
        }


    }

    const headCells = [
        { id: 'imsi', label: 'IMSI', isSearchable: true },
        { id: 'msisdn', label: 'MSISDN', isSearchable: true },
        { id: 'simId', label: 'Sim Id', isSearchable: true },
        { id: 'moduleType', label: 'Module Type', isSearchable: true },
        { id: 'iccid', label: 'ICCID', isSearchable: true },
        { id: 'serialNumber', label: 'Serial Number', isSearchable: true }
    ];

    const soracomUserDetails = soracomUser ? (<TableContainer component={Paper}>
        <Table aria-label="Soracom User Details">
            <TableBody>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>{soracomUser.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Operator Id</TableCell>
                    <TableCell>{soracomUser.operatorId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>{soracomUser.userName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Registered Subscribers</TableCell>
                    <TableCell>{soracomUser.registered}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Activated Subscribers</TableCell>
                    <TableCell>{soracomUser.activated}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Added On</TableCell>
                    <TableCell>{new Date(soracomUser.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>) : (<CircularProgress />);

    // const subscribersTable = (<CircularProgress />);
    const subscribersTable = soracomSubscribers ? (<TableWithExternalSearchAndPagination rows={soracomSubscribers[0]} totalRows={soracomSubscribers[1]} headCells={headCells} onChangeGetData={handleChangeData} name="Soracom Subscribers" />) : (<CircularProgress />);

    const buttonsDisplay = editMode ? (<>
        <Button variant="contained" color="danger" startIcon={<PasswordIcon />} onClick={() => { setUpdateUserCredentialsMode(true); setEditMode(false) }} disabled={isProcessing}>Update Credentials</Button>
        <Button variant="contained" color="danger" startIcon={<DeleteOutlineIcon />} onClick={() => setDeleteMode(true)} disabled={isProcessing}>Delete</Button>
    </>) : (<>
        <Button variant="contained" color="default" startIcon={<EditIcon />} disabled={isProcessing} onClick={() => setEditMode(true)}>Options</Button>
        <Button variant="contained" color="primary" startIcon={<CloseIcon />} onClick={() => props.setModalOpen(false)} >Close</Button>
    </>)

    return (
        <GridContainer xs={12}>
            {deleteMode ? (
                <GridItem xs={12}>
                    <Typography variant='body1'>Please type <b>`{randomString}`</b> to confirm the deletion.</Typography>
                    <Typography variant='body2'>Please note that this action deletes this user details from this platform but will not delete from the Soracom portal. <br />If you need to delete please do so manually manually by logging in to the Soracom portal.</Typography>
                    <CustomInput
                        labelText="Delete Confirmation"
                        id="deleteConfirmation"
                        formControlProps={{
                            fullWidth: true,
                            className: classes.root
                        }}
                        inputProps={{
                            value: enteredRandomString,
                            onChange: (event) => setEnteredRandomString(event.target.value),
                        }}
                    />
                    <Button variant="contained" color="danger" startIcon={<DeleteOutlineIcon />} onClick={() => {
                        //Check if the random string and the entered random string match
                        //if not display warning and exit out of the deletion mode
                        if (enteredRandomString === randomString) {
                            handleDelete();
                        } else {

                            props.showNotification({
                                color: 'error',
                                message: `The input does not match. Deletion aborted.`,
                                time: 5000
                            });
                        }
                        setDeleteMode(false);
                    }} disabled={isProcessing}>Delete</Button>
                </GridItem>
            ) : (
                <GridItem xs={12} className={classes.buttonHolder}>
                    <div className={classes.buttonHolder}>
                        {!updateUserCredentialsMode && buttonsDisplay}
                    </div>
                </GridItem>
            )}

            {updateUserCredentialsMode ? (
                <GridItem xs={12}>
                    <form onSubmit={handleFormSubmit}>

                        <Typography >Update soracom user credentials</Typography>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Operator Name"
                                inputProps={{
                                    value: soracomUser.operatorId,
                                    disabled: true
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Operator ID"
                                inputProps={{
                                    value: soracomUser.name,
                                    disabled: true
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}
                            />
                        </GridItem>


                        <GridItem xs={12}>
                            <CustomInput
                                labelText="User Name *"
                                inputProps={{
                                    value: userName,
                                    onChange: handleUserNameEvent,
                                    error: userNameError
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root
                                }}

                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Password *"
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.root,
                                }}
                                inputProps={{
                                    type: "password",
                                    value: password,
                                    onChange: handlePasswordEvent,
                                    error: passwordError
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} className={classes.logoHolder}>
                            <div className={classes.buttonHolder}>
                                <Button type="submit" color="primary"
                                    disabled={isProcessing}
                                >Update Credentials</Button>
                            </div>
                        </GridItem>

                    </form>

                </GridItem>
            ) : (<>
                <GridItem xs={12} md={7}>
                    <Typography variant='h6'>User Details</Typography>
                    {soracomUserDetails}
                </GridItem>
                <GridItem xs={12} md={5}>
                    {soracomUser && Object.keys(soracomUser).length ? (<ActiveRegisteredBar registered={soracomUser.registered} activated={soracomUser.activated} />) : ''}
                </GridItem>
                <br />
                <GridItem xs={12}>

                    <Typography variant='h6'>Related Subscribers</Typography>
                    {subscribersTable}
                </GridItem>
            </>
            )}
        </GridContainer>
    )
}

const styles = makeStyles({
    buttonHolder: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export default connect(null, actions)(SoracomUserDetails);
