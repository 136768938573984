import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import moment from 'moment-timezone';
import ChartDataLabels from 'chartjs-plugin-datalabels'


import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import { nFormatter } from '../../helpers/helper.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);



const getSubscribersLogs = (subscribersLogs, selectedLegends) => {
  const tmpObj = {};
  let min, max;
  const selectedDays = [];

  for (const operatorId of Object.keys(subscribersLogs)) {
    if (selectedLegends[operatorId]) {
      if (!tmpObj[operatorId]) {
        tmpObj[operatorId] = { registered: {}, activated: {} }
      }
    } else {
      continue;
    }
    for (const day of Object.keys(subscribersLogs[operatorId])) {
      const x = moment(day, 'YYYYMMDD').format('YYYY-MM-DD');
      const unixTime = moment(day, 'YYYYMMDD').unix();
      tmpObj[operatorId].registered[x] = subscribersLogs[operatorId][day].registered;
      tmpObj[operatorId].activated[x] = subscribersLogs[operatorId][day].activated;
      if (!min || unixTime < min) {
        min = unixTime;
      }
      if (!max || unixTime > max) {
        max = unixTime;
      }
    }
  }

  for (let current = min; current < max; current += 7 * 24 * 60 * 60) {
    selectedDays.push(moment.unix(current).format('YYYY-MM-DD'));
  }
  selectedDays.push(moment.unix(max).format('YYYY-MM-DD'));

  return [
    {
      datasets: Object.keys(tmpObj).map((operatorId) => {
        return {
          label: selectedLegends[operatorId] ? selectedLegends[operatorId].name : operatorId,
          data: selectedDays.map(x => ({ x, y: tmpObj[operatorId].registered[x] })),
          borderColor: selectedLegends[operatorId].color,
          backgroundColor: selectedLegends[operatorId].color
        }
      })
    },
    {
      datasets: Object.keys(tmpObj).map((operatorId) => {
        return {
          label: selectedLegends[operatorId] ? selectedLegends[operatorId].name : operatorId,
          data: selectedDays.map(x => ({ x, y: tmpObj[operatorId].activated[x] })),
          borderColor: selectedLegends[operatorId].color,
          backgroundColor: selectedLegends[operatorId].color
        }
      })
    }
  ]

}

const SubscribersLogsChart = props => {


  //We get two things from the props daily billings and legend

  const { subscriberLogs, legend } = props;
  let registeredChart = '';
  let activatedChart = '';
  if (Object.keys(subscriberLogs).length && Object.keys(legend).length) {
    //using the legend get the selected legends
    const selectedLegends = Object.keys(legend).reduce((newDict, operatorId) => !legend[operatorId].disabled ? ({ ...newDict, [operatorId]: legend[operatorId] }) : newDict, {});
    //with the selected legends get the data to display
    const [regsteredData, activatedData] = getSubscribersLogs(subscriberLogs, selectedLegends);

    const registeredChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        datalabels: {
          display: Object.keys(selectedLegends).length < 5,
          color: "black",
          formatter: (obj) => nFormatter(obj.y),
          anchor: "end",
          offset: -20,
          align: "start"
        },
        title: {
          display: true,
          text: 'Subscribers Logs For Customer (Registered)',
        },
      },
    };

    const activatedChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false
        },
        datalabels: {
          display: Object.keys(selectedLegends).length < 5,
          color: "black",
          formatter: (obj) => nFormatter(obj.y),
          anchor: "end",
          offset: -20,
          align: "start"
        },
        title: {
          display: true,
          text: 'Subscribers Logs For Customer (Activated)',
        },
      },
    };
    registeredChart = (<Line options={registeredChartOptions} data={regsteredData} plugins={[ChartDataLabels]} />)
    activatedChart = (<Line options={activatedChartOptions} data={activatedData} plugins={[ChartDataLabels]} />)
  }

  return (
    <>
      <GridItem xs={12}>
        <Card>
          {registeredChart}
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          {activatedChart}
        </Card>
      </GridItem>
    </>
  )
}

export default SubscribersLogsChart;